import React, { FC, useContext } from "react";
import { Route, Routes } from "react-router-dom";
import getFormJourney from "../constants/journeys/formJourney";
import { JourneyTypes } from "../constants/journeys/journeyNames";
import ActivityContext from "../contexts/ActivityContext";
import logError from "../utils/logError";

type ActivityRoutesProps = {
  children: ({
    journey,
    page,
    currentStep,
    totalSteps,
  }: {
    journey: JourneyTypes;
    page: string;
    currentStep: number;
    totalSteps: number;
  }) => React.ReactElement | null;
};

const ActivityRoutes: FC<ActivityRoutesProps> = ({ children }) => {
  const activity = useContext(ActivityContext);
  const { journey, sourceCode } = activity;

  const formJourney = getFormJourney(journey, activity);

  if (typeof journey === "undefined" || (formJourney && !formJourney.pages)) {
    logError("E0003", { journey, sourceCode });
    return null;
  }
  // the thank-you page is a complete step in the config, but we don't count it in the total
  // in the step tracker
  const totalSteps = Object.entries(formJourney.pages).filter(
    ([page]) => page !== "thank-you"
  ).length;

  // call the children render prop function once for each page in the form,
  // passing the page's config plus the currentStep and totalStep numbers.
  // each page becomes it's own <Route> element rendered with a different config.
  // if the function returns nothing, no <Route> element is returned for that page.
  return (
    <Routes>
      {Object.entries(formJourney.pages).map(([page, pageConfig], index) => {
        const routeChildren = children({
          journey: journey as JourneyTypes,
          page,
          currentStep: index + 1,
          totalSteps,
          ...pageConfig,
        });

        return routeChildren ? (
          <Route
            key={page}
            path="/"
            element={routeChildren}
            children={
              <Route
                path={pageConfig.path}
                key={page}
                element={routeChildren}
              />
            }
          />
        ) : null;
      })}
    </Routes>
  );
};

export default ActivityRoutes;
