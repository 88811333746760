import {
  Box,
  ErrorText,
  LegendWrapper,
  Radio,
} from "@cruk/cruk-react-components";
import { FormFieldProps } from "../../types";

const MaleFemaleStyles = ({
  values,
  field,
  touched,
  errors,
  journeyHelper,
  journey,
  getFieldProps,
}: FormFieldProps) =>
  values.haveEnteredEvent &&
  (values.tshirtOrVest === "tshirt" || values.tshirtOrVest === "vest") ? (
    <Box marginBottom="s" key={field}>
      <LegendWrapper
        legendText={
          journeyHelper.isSportsFormExternalJourney(journey)
            ? `What style of top shall we send you?`
            : `What style of top would they like?`
        }
        hintText={
          journeyHelper.isSportsFormExternalJourney(journey)
            ? "Our tops come in two styles. Male a unisex boxier fit and female a more form fitted style."
            : ""
        }
        required
      >
        <Radio
          {...getFieldProps("maleFemaleStyles")}
          aria-label="Select male to get a top with a boxier fit"
          value="male"
          checked={values.maleFemaleStyles === "male"}
        >
          Male
        </Radio>
        <Radio
          {...getFieldProps("maleFemaleStyles")}
          aria-label="Select female to get a top with a more form-fitted style"
          value="female"
          checked={values.maleFemaleStyles === "female"}
        >
          Female
        </Radio>
      </LegendWrapper>
      <ErrorText>
        {touched.maleFemaleStyles ? errors.maleFemaleStyles : undefined}
      </ErrorText>
    </Box>
  ) : null;

export default MaleFemaleStyles;
