// eslint-disable-next-line eslint-comments/disable-enable-pair

/* eslint-disable @typescript-eslint/no-unsafe-return */
// eslint-disable-next-line eslint-comments/disable-enable-pair

/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { FC } from "react";
import styled from "styled-components/macro";

const Div = styled.div`
  margin-bottom: ${({ theme }) => theme.spacing.s};
  @media (min-width: ${({ theme }) => theme.breakpoint.desktop}) {
    display: flex !important;
    flex-direction: row-reverse;
    justify-content: flex-end;
    .backButton .nextButton {
      width: auto;
    }
  }
  @media (min-width: ${({ theme }) => theme.breakpoint.tablet}) {
    display: flex !important;
    flex-direction: row-reverse;
    justify-content: flex-end;
    .backButton .nextButton {
      width: auto;
    }
  }
  @media (max-width: ${({ theme }) => theme.breakpoint.mobile}) {
    display: block;
    .nextButton,
    .backButton {
      width: 100%;
      margin-top: 0px !important;
    }
    .nextBox {
      margin-left: 0px !important;
      margin-bottom: ${({ theme }) => theme.spacing.xs};
    }
    .backButton {
      width: 100%;
      margin-top: ${({ theme }) => theme.spacing.xs};
    }
  }
`;

const StepsButtonWrapper: FC = ({ children }) => <Div>{children}</Div>;

export default StepsButtonWrapper;
