import { Box, Heading } from "@cruk/cruk-react-components";

const FormPageBannerText = ({ bannerText }: { bannerText: string }) => {
  return bannerText ? (
    <Box backgroundColor="primary" paddingVertical="l" marginBottom="xl">
      <Heading h3 textColor="textLight" textAlign="center" marginBottom="none">
        {bannerText}
      </Heading>
    </Box>
  ) : null;
};

export default FormPageBannerText;
