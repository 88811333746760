import styled from "styled-components/macro";

const DividerLine = styled.hr`
  border: 1px solid
    ${
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-return
      ({ theme }) => theme.tokenColors.grey_300
    };
  margin-top: 1.5rem;
`;

export default DividerLine;
