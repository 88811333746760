import styled from "styled-components/macro";

const GenericEventSuccessIconWrapper = styled.div`
  svg {
    position: absolute;
    margin-top: -${
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-return
        ({ theme }) => theme.spacing.xl
      };
    right: ${
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-return
      ({ theme }) => theme.spacing.xxl
    };
    transform: translateY(-50%);
  }
`;

export default GenericEventSuccessIconWrapper;
