import {
  Box,
  ErrorText,
  LegendWrapper,
  Radio,
} from "@cruk/cruk-react-components";
import ImageBox from "../../components/ImageBox";
import SpaceAroundRows from "../../components/SpaceAroundRows";
import tShirtImage from "../../assets/sports-forms-images/tshirt.png";
import vestImage from "../../assets/sports-forms-images/vest.jpg";
import { RegistrationType } from "../../contexts/RegistrationContext";
import { FormFieldProps } from "../../types";

const TShirtOrVest = ({
  journeyHelper,
  journey,
  values,
  field,
  getFieldProps,
  setValues,
  touched,
  errors,
}: FormFieldProps) =>
  (
    journeyHelper.isSportsFormContactCentreJourney(journey)
      ? values.hasOnlineFundraisingPage
      : values.haveEnteredEvent
  ) ? (
    <Box key={field}>
      <Box marginBottom="s">
        <LegendWrapper
          required
          hintText={
            journeyHelper.isSportsFormExternalJourney(journey)
              ? `We can send you a Cancer Research UK running t-shirt or vest you can personalise with your name for race day. Let us know your preference`
              : ``
          }
          legendText={
            journeyHelper.isSportsFormExternalJourney(journey)
              ? `Would you like a free t-shirt or vest?`
              : `Would they like a free top?`
          }
        >
          <Radio
            {...getFieldProps("tshirtOrVest")}
            value="tshirt"
            aria-label="Radio button to be checked for a t-shirt you will see an image of a t-shirt "
            key={field}
            checked={values.tshirtOrVest === "tshirt"}
          >
            T-Shirt
          </Radio>
          <Radio
            {...getFieldProps("tshirtOrVest")}
            value="vest"
            aria-label="Radio button to be checked for a vest you will see an image of a vest"
            checked={values.tshirtOrVest === "vest"}
          >
            Vest
          </Radio>
          <Radio
            {...getFieldProps("tshirtOrVest")}
            value="neither"
            onChange={(e) => {
              setValues({
                ...(values as RegistrationType),
                tshirtOrVest: e.target.value,
                ironOnText: "",
                tshirtVestSize: "",
                maleFemaleStyles: "",
              });
            }}
            aria-label="Radio button to be checked if neither tshirt or vest required you can now proceed to the next step"
            checked={values.tshirtOrVest === "neither"}
          >
            Neither
          </Radio>
          <ErrorText>
            {touched.tshirtOrVest ? errors.tshirtOrVest : undefined}
          </ErrorText>
        </LegendWrapper>
      </Box>
      <Box marginBottom="s">
        {values.tshirtOrVest === "" && (
          <SpaceAroundRows>
            <ImageBox
              alt="Displays a tshirt with short sleeves"
              style={{ width: "auto" }}
              src={tShirtImage}
            />
            <ImageBox
              alt="Displays a sleeveless vest"
              style={{ width: "auto", marginBottom: "2rem" }}
              src={vestImage}
            />
          </SpaceAroundRows>
        )}
        {values.tshirtOrVest === "tshirt" && (
          <SpaceAroundRows>
            <ImageBox
              alt="Displays a tshirt with short sleeves"
              style={{ width: "auto" }}
              src={tShirtImage}
            />
          </SpaceAroundRows>
        )}
        {values.tshirtOrVest === "vest" && (
          <SpaceAroundRows>
            <ImageBox
              alt="Displays a sleevless vest"
              style={{ width: "auto" }}
              src={vestImage}
            />
          </SpaceAroundRows>
        )}
      </Box>
    </Box>
  ) : null;

export default TShirtOrVest;
