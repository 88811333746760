import { InfoBox } from "@cruk/cruk-react-components";
import styled from "styled-components";

const StyledInfoBox = styled(InfoBox)`
  div {
    margin-bottom: 0;
  }
  @media (max-width: ${({ theme }) => theme?.breakpoint?.tablet}) {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    div {
      margin-right: 0 !important;
      margin-bottom: 1rem;
    }
    div:last-child > p {
      text-align: center !important;
    }
  }
`;

export default StyledInfoBox;
