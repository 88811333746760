import { Box, Text } from "@cruk/cruk-react-components";
import DateField from "../../components/DateField";
import { FormFieldProps } from "../../types";
import renderCustomText from "../../utils/renderCustomText";

const DateOfBirth = ({
  activity,
  touched,
  errors,
  field,
  values,
  handleBlur,
  filterHandleChange,
}: FormFieldProps) => {
  const touchedAll =
    touched.dateOfBirthDay &&
    touched.dateOfBirthMonth &&
    touched.dateOfBirthYear;
  const error = touchedAll
    ? errors.dateOfBirthDay || errors.dateOfBirthMonth || errors.dateOfBirthYear
    : "";

  return (
    <Box marginBottom="s" key={field}>
      <DateField
        label="Date of birth"
        hintText={
          <>
            <Text>For example 14 03 1987</Text>
            {renderCustomText(activity.dobHelpText)}
          </>
        }
        day={values.dateOfBirthDay as string}
        month={values.dateOfBirthMonth as string}
        year={values.dateOfBirthYear as string}
        dayName="dateOfBirthDay"
        monthName="dateOfBirthMonth"
        yearName="dateOfBirthYear"
        dayHasError={touchedAll && !!errors.dateOfBirthDay}
        monthHasError={touchedAll && !!errors.dateOfBirthMonth}
        yearHasError={touchedAll && !!errors.dateOfBirthYear}
        errorMessage={error}
        onChange={filterHandleChange()}
        onBlur={handleBlur}
      />
    </Box>
  );
};

export default DateOfBirth;
