import styled from "styled-components/macro";

const ListItem = styled.li`
  align-items: center;
  background-color: ${({ theme }) =>
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-return
    theme.colors.backgroundLight};
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  padding: 5px;
  &:hover,
  &:focus {
    background-color: #ddd;
  }
`;

export default ListItem;
