import { Box, TextField } from "@cruk/cruk-react-components";
import { FormFieldProps } from "../../types";

const LastName = ({
  field,
  touched,
  errors,
  activity,
  handleBlur,
  getFieldProps,
  isRequired,
}: FormFieldProps) => (
  <Box marginBottom="s" key={field}>
    <TextField
      label={activity.lastNameLabel || "Last name"}
      aria-label="This is a text field to type your last name please enter a valid name with a minimum of two characters to proceed"
      {...getFieldProps("lastName")}
      required={isRequired("lastName")}
      errorMessage={touched.lastName ? errors.lastName : undefined}
      onBlur={handleBlur}
    />
  </Box>
);

export default LastName;
