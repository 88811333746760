import { Box, TextField } from "@cruk/cruk-react-components";
import { FormFieldProps } from "../../types";

const EmailAddress = ({
  field,
  touched,
  errors,
  formJourneyPage,
  handleBlur,
  getFieldProps,
  isRequired,
}: FormFieldProps) => (
  <Box marginBottom="s" key={field}>
    <TextField
      label={formJourneyPage.emailAddressLabel || "Email address"}
      hintText={formJourneyPage.emailAddressHintText}
      aria-label="This is a text field to type your email please enter a valid email to proceed"
      type="email"
      {...getFieldProps("emailAddress")}
      required={isRequired("emailAddress")}
      errorMessage={touched.emailAddress ? errors.emailAddress : undefined}
      onBlur={handleBlur}
    />
  </Box>
);

export default EmailAddress;
