import { Box, LegendWrapper, Radio, Text } from "@cruk/cruk-react-components";
import { FormFieldProps } from "../../types";
import JourneyHelper from "../../utils/journeyHelper";

const { isDIYJourney } = new JourneyHelper();

const DeliveryMethod2 = ({
  field,
  journey,
  getFieldProps,
  touched,
  values,
  errors,
}: FormFieldProps) => (
  <Box marginBottom="s" key={field}>
    {journey === "general-fundraising-pack-option" && (
      <Text textWeight={700}>Your pack</Text>
    )}
    <LegendWrapper
      required={isDIYJourney(journey)}
      legendText={
        isDIYJourney(journey)
          ? "I'd like to receive my pack by"
          : "How would you like us to send your pack?"
      }
      errorMessage={touched.deliveryMethod ? errors.deliveryMethod : undefined}
    >
      <Radio
        {...getFieldProps("deliveryMethod")}
        value="email"
        aria-label="Click if you would like your pack to be sent by email only"
        checked={values.deliveryMethod === "email"}
      >
        Email only
      </Radio>

      <Radio
        {...getFieldProps("deliveryMethod")}
        value="post"
        aria-label="Click if you would like your pack to be sent by email and post"
        checked={values.deliveryMethod === "post"}
      >
        Email and post
      </Radio>
    </LegendWrapper>
  </Box>
);

export default DeliveryMethod2;
