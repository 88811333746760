import { Box, Text } from "@cruk/cruk-react-components";
import DateField from "../../components/DateField";
import { FormFieldProps } from "../../types";
import renderCustomText from "../../utils/renderCustomText";

const ActivityDate = ({
  values,
  field,
  touched,
  activity,
  errors,
  handleBlur,
  filterHandleChange,
}: FormFieldProps) => {
  const touchedAll =
    touched.celebrationDateDay &&
    touched.celebrationDateMonth &&
    touched.celebrationDateYear;
  const error = touchedAll
    ? errors.celebrationDateDay ||
      errors.celebrationDateMonth ||
      errors.celebrationDateYear
    : "";

  return (
    <Box marginBottom="s" key={field}>
      <DateField
        label={activity.activityDateHeader}
        hintText={
          <>
            <Text>For example 15 03 2021</Text>
            {renderCustomText(activity.activityDateHelpText)}
          </>
        }
        day={values.celebrationDateDay as string}
        month={values.celebrationDateMonth as string}
        year={values.celebrationDateYear as string}
        dayName="celebrationDateDay"
        monthName="celebrationDateMonth"
        yearName="celebrationDateYear"
        dayHasError={touchedAll && !!errors.celebrationDateDay}
        monthHasError={touchedAll && !!errors.celebrationDateMonth}
        yearHasError={touchedAll && !!errors.celebrationDateYear}
        errorMessage={error}
        onChange={filterHandleChange()}
        onBlur={handleBlur}
      />
    </Box>
  );
};

export default ActivityDate;
