import { Box, Text, TextField } from "@cruk/cruk-react-components";
import { FormFieldProps } from "../../types";

const IronOnText = ({
  values,
  field,
  journeyHelper,
  journey,
  getFieldProps,
  errors,
  touched,
}: FormFieldProps) => {
  const getIronOnTextRemainingLength = (ironOnText: string) => {
    if (ironOnText.includes(" ")) {
      const removeWhiteSpaceString = ironOnText.replaceAll(" ", "");
      return 10 - removeWhiteSpaceString.length;
    }
    return 10 - ironOnText.length;
  };

  return (values.tshirtVestSize as string) ? (
    <Box marginBottom="s" key={field}>
      <TextField
        label={
          journeyHelper.isSportsFormExternalJourney(journey)
            ? "What iron-on letters would you like?"
            : "What iron-on letters would they like?"
        }
        hintText={
          journeyHelper.isSportsFormExternalJourney(journey)
            ? "We can also send iron-on letters so you can personalise your top. For example 'Catherine', 'For Gran' or your team name"
            : "We can also send iron on letters so they can personalise thier t-shirt or vest. For example 'Catherine', 'For Gran' or their team name"
        }
        placeholder=""
        aria-label="This is a text field to add a custom message if you want it ironed on to your t-shirt or vest. For example you can use your team name or someone you want to dedicate your fundraising effort to"
        {...getFieldProps("ironOnText")}
        errorMessage={touched.ironOnText ? errors.ironOnText : undefined}
      />
      {getIronOnTextRemainingLength(values.ironOnText) >= 0 && (
        <Text textAlign="right">
          {getIronOnTextRemainingLength(values.ironOnText)} characters remaining
        </Text>
      )}
    </Box>
  ) : null;
};

export default IronOnText;
