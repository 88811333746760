import { Box, TextField } from "@cruk/cruk-react-components";
import { FormFieldProps } from "../../types";

const OrganisationName = ({
  activity,
  touched,
  errors,
  field,
  getFieldProps,
  isRequired,
}: FormFieldProps) => (
  <Box marginBottom="s" key={field}>
    <TextField
      label={activity.organisationName}
      type="text"
      {...getFieldProps("organisationName")}
      id="organisationName"
      required={isRequired("organisationName")}
      errorMessage={
        touched.organisationName ? errors.organisationName : undefined
      }
    />
  </Box>
);

export default OrganisationName;
