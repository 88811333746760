export const SF_CHALLENGES = "sf-challenges";
export const CHALLENGE = "challenge";
export const DRYATHLON = "dryathlon";
export const SPORTS_FORM = "sports-form";
export const SPORTS_FORMS_CONTACT_CENTRE = "sports-forms-contact-centre";
export const SPORTS_FORMS_PROMOTER = "sports-form-promoter";
export const SPORTS_FORM_WITHOUT_GIVING_PAGE =
  "sports-form-without-giving-page";
export const GENERAL_FUNDRAISING_PACK_OPTION =
  "general-fundraising-pack-option";
export const VF = "vf";
export const VF_FUNDREG = "vf-fundreg";
export const LEAD_GENERATION_1 = "lead-generation-1";
export const LEAD_GENERATION_2 = "lead-generation-2";
export const GIFTS_IN_WILLS_GUIDE = "gifts-in-wills-guide";
export const ORGANISATION = "organisation";
export const GENERAL_FUNDRAISING_2 = "general-fundraising-2";
export const ALWAYS_ON_2 = "always-on-2";
export const ALWAYS_ON = "always-on";
export const RUN_56 = "run-56";
export const CELEBRATION_0 = "0-celebration";
export const DIY_PARK_RUNNER = "diy-park-runner";
export const DIY_COUCH_TO_5K_JOGGER = "diy-couch-to-5k-jogger";

export type JourneyTypes =
  | typeof SF_CHALLENGES
  | typeof CHALLENGE
  | typeof DRYATHLON
  | typeof SPORTS_FORM
  | typeof SPORTS_FORMS_CONTACT_CENTRE
  | typeof SPORTS_FORMS_PROMOTER
  | typeof SPORTS_FORM_WITHOUT_GIVING_PAGE
  | typeof GENERAL_FUNDRAISING_PACK_OPTION
  | typeof VF
  | typeof VF_FUNDREG
  | typeof LEAD_GENERATION_1
  | typeof LEAD_GENERATION_2
  | typeof GIFTS_IN_WILLS_GUIDE
  | typeof ORGANISATION
  | typeof GENERAL_FUNDRAISING_2
  | typeof ALWAYS_ON_2
  | typeof ALWAYS_ON
  | typeof RUN_56
  | typeof CELEBRATION_0
  | typeof DIY_PARK_RUNNER
  | typeof DIY_COUCH_TO_5K_JOGGER
  | "undefined";
