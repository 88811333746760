import { Box, Checkbox, LegendWrapper } from "@cruk/cruk-react-components";
import { FormFieldProps } from "../../types";

const HaveEnteredEvent = ({
  field,
  values,
  errors,
  touched,
  eventRegistered,
  journey,
  journeyHelper,
  getFieldProps,
}: FormFieldProps) => (
  <Box marginBottom="s" key={field}>
    {(eventRegistered || (values.eventCode && values.eventName)) && (
      <>
        <LegendWrapper
          required={
            !!eventRegistered || !!(values.eventCode && values.eventName)
          }
          hintText={
            journeyHelper.isSportsFormExternalJourney(journey)
              ? `Please only complete this form if you already have a place in the ${
                  eventRegistered?.title || values?.eventName || ""
                }. If you don’t have a place yet, return to the official event website for more info on how to enter`
              : `Please only complete this form if the supporter has secured their own place already. If not, please instruct them to return to the offical event website for more info on how to enter or offer them a charity place if possible`
          }
          legendText={
            journeyHelper.isSportsFormExternalJourney(journey)
              ? `Do you already have a place in the ${
                  eventRegistered?.title || values?.eventName || ""
                }?`
              : `Have the supporter already entered ${
                  eventRegistered?.title || values?.eventName || ""
                }?`
          }
        >
          <Checkbox
            type="checkbox"
            {...getFieldProps("haveEnteredEvent")}
            aria-label="You are on a tick box. Please tick the box to proceed to next step if you have a place in the event - more instructions to follow"
            checked={values.haveEnteredEvent as boolean}
            errorMessage={
              touched.haveEnteredEvent ? errors.haveEnteredEvent : undefined
            }
          >
            {journeyHelper.isSportsFormExternalJourney(journey) && (
              <>I already have a place at this event</>
            )}
            {journeyHelper.isSportsFormContactCentreJourney(journey) && (
              <>They have a place at this event</>
            )}
          </Checkbox>
        </LegendWrapper>
      </>
    )}
  </Box>
);

export default HaveEnteredEvent;
