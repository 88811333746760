import {
  DIY_COUCH_TO_5K_JOGGER,
  DIY_PARK_RUNNER,
} from "../constants/journeys/journeyNames";

export class JourneyHelper {
  isSportsFormJourney(journey: string): boolean {
    return (
      journey === "sports-form" ||
      journey === "sports-forms-contact-centre" ||
      journey === "sports-form-promoter" ||
      journey === "sports-form-without-giving-page"
    );
  }

  isSportsFormContactCentreJourney(journey: string): boolean {
    return journey === "sports-forms-contact-centre";
  }

  isSportsFormExternalJourney(journey: string): boolean {
    return (
      journey === "sports-form" ||
      journey === "sports-form-promoter" ||
      journey === "sports-form-without-giving-page"
    );
  }

  isSportsFormPromoterJourney(journey: string): boolean {
    return journey === "sports-form-promoter";
  }

  isInternationalAddressReminder(journey: string): boolean {
    return journey === "gifts-in-wills-guide";
  }

  isSocialFundraisingJourney(journey: string): boolean {
    return journey === "sf-challenges";
  }

  isSportsFormWithEventCodeJourney(journey: string): boolean {
    return (
      journey === "sports-form" || journey === "sports-form-without-giving-page"
    );
  }

  isDIYJourney(journey: string) {
    return journey === DIY_COUCH_TO_5K_JOGGER || journey === DIY_PARK_RUNNER;
  }

  isGiftsInWillsJourney(journey: string): boolean {
    return journey === "gifts-in-wills-guide";
  }
}
export default JourneyHelper;
