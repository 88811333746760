// eslint-disable-next-line eslint-comments/disable-enable-pair

/* eslint-disable @typescript-eslint/no-unsafe-return */
// eslint-disable-next-line eslint-comments/disable-enable-pair

/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { ErrorText, Text, TextField } from "@cruk/cruk-react-components";
import { ChangeEventHandler, FC, InputHTMLAttributes, ReactNode } from "react";
import styled from "styled-components/macro";
import { trackDataDogError } from "../utils/dataDog";

// type DateFieldProps = InputHTMLAttributes<{}> & {
type DateFieldProps = InputHTMLAttributes<Record<string, any>> & {
  label: string;
  hintText?: ReactNode;
  day: string;
  month: string;
  year: string;
  dayName?: string;
  monthName?: string;
  yearName?: string;
  dayHasError?: boolean;
  monthHasError?: boolean;
  yearHasError?: boolean;
  errorMessage?: string;
  onChange?: ChangeEventHandler<HTMLInputElement>;
  handleBlur?: (e: React.FocusEvent<unknown>) => void;
};

const Fieldset = styled.fieldset`
  margin: 0;
  padding: 0;
  border: 0;
`;

const HintTextWrapper = styled.div`
  p {
    margin-bottom: 0;
  }

  p:last-child {
    margin-bottom: ${({ theme }) => theme.spacing.xs};
  }
`;

const DateTextFieldWrapper = styled.div`
  display: inline-block;
  width: 60px;
  float: left;
  margin-right: ${({ theme }) => theme.spacing.xs};

  span {
    margin-bottom: 0.25rem; // this is smaller than xxs spacing
    font-weight: ${({ theme }) => theme.typography.fontWeightMedium};
  }
`;

const LargeDateTextFieldWrapper = styled(DateTextFieldWrapper)`
  width: 80px;
`;

const ErrorTextWrapper = styled.div`
  clear: left;
`;

const DateField: FC<DateFieldProps> = ({
  label,
  hintText,
  day,
  month,
  year,
  dayName = "day",
  monthName = "month",
  yearName = "year",
  dayHasError,
  monthHasError,
  yearHasError,
  errorMessage,
  onChange = () => {},
  handleBlur = () => {},
  // touchedAll,
}) => (
  <Fieldset>
    <Text as="legend" textWeight={700}>
      {label}
    </Text>
    {hintText && <HintTextWrapper>{hintText}</HintTextWrapper>}
    <DateTextFieldWrapper>
      <TextField
        label="Day"
        type="text"
        name={dayName}
        required
        maxLength={2}
        autoComplete="bday-day"
        pattern="[0-9]*"
        inputMode="numeric"
        value={day}
        onChange={onChange}
        onBlur={handleBlur}
        hasError={dayHasError}
      />
    </DateTextFieldWrapper>
    <DateTextFieldWrapper>
      <TextField
        label="Month"
        type="text"
        name={monthName}
        required
        maxLength={2}
        autoComplete="bday-month"
        pattern="[0-9]*"
        inputMode="numeric"
        value={month}
        onChange={onChange}
        onBlur={handleBlur}
        hasError={monthHasError}
      />
    </DateTextFieldWrapper>
    <LargeDateTextFieldWrapper>
      <TextField
        label="Year"
        type="text"
        name={yearName}
        required
        maxLength={4}
        autoComplete="bday-year"
        pattern="[0-9]*"
        inputMode="numeric"
        value={year}
        onChange={onChange}
        onBlur={handleBlur}
        hasError={yearHasError}
      />
    </LargeDateTextFieldWrapper>
    {errorMessage && (
      <ErrorTextWrapper>
        {trackDataDogError("DateField", {
          error: errorMessage,
        })}
        <ErrorText>{errorMessage}</ErrorText>
      </ErrorTextWrapper>
    )}
  </Fieldset>
);

export default DateField;
