import { Text } from "@cruk/cruk-react-components";
import React, { FC } from "react";
import { FormikValues } from "formik";
import styled from "styled-components/macro";
import { JourneyTypes } from "../constants/journeys/journeyNames";
import {
  CmInchesData,
  SizeGuideValues,
  SizeGuideValuesType,
  SizesData,
} from "../constants/sizeGuideValues";
import JourneyHelper from "../utils/journeyHelper";

const StyledSizeGuide = styled.table`
  width: 100%;
  border: none;
  padding: 0;
  th,
  td {
    border: none;
    text-align: left;
  }
  td {
    border-top: 1px solid ${({ theme }) => theme.colors.buttonBorder};
  }
`;

const StyledSizeGuideWithMargin = styled(StyledSizeGuide)`
  margin-top: 2rem;
`;

type SizeGuideProps = {
  values: FormikValues;
  journey: JourneyTypes;
  theme?: "cruk" | "su2c" | "rfl";
};

const SizeGuide: FC<SizeGuideProps> = ({ values, journey, theme = "cruk" }) => {
  const { isSocialFundraisingJourney, isSportsFormJourney } =
    new JourneyHelper();
  const SizeGuide = new SizeGuideValues(values);
  const currentSize = SizeGuide.currentSize(theme, journey);

  const tableHeaders = () =>
    React.Children.toArray(
      currentSize.headers.map(({ title, abbr, detail }) => (
        <th>
          {title} {abbr && <abbr title="centimetres">({abbr})</abbr>}{" "}
          {detail && <abbr>({detail})</abbr>}
        </th>
      ))
    );

  const tableTitle = (title: string) => {
    return (
      <Text
        textColor="primary"
        textSize="m"
        textWeight={600}
        marginBottom="xxs"
      >
        {title}
      </Text>
    );
  };

  const tableBody = (sizeData: SizeGuideValuesType) => {
    return React.Children.toArray(
      sizeData.data.map((sizeItem) => {
        const { sizeLabel, cm, inches, S, M, L, XL, XXL } =
          sizeItem as SizesData & CmInchesData;

        return isSportsFormJourney(journey) ? (
          <tr>
            <td>{sizeLabel}</td>
            <td>{S}</td>
            <td>{M}</td>
            <td>{L}</td>
            <td>{XL}</td>
            <td>{XXL}</td>
          </tr>
        ) : (
          <tr>
            <td>{sizeLabel}</td>
            <td>{cm}</td>
            <td>{inches}</td>
          </tr>
        );
      })
    );
  };

  return (
    <>
      <StyledSizeGuide>
        {isSocialFundraisingJourney(journey) && (
          <caption>{tableTitle("CRUK T-shirt")}</caption>
        )}
        <thead>{tableHeaders()}</thead>
        <tbody>{tableBody(currentSize)}</tbody>
      </StyledSizeGuide>

      {isSocialFundraisingJourney(journey) && (
        <StyledSizeGuideWithMargin>
          <caption>{tableTitle("CRUK Tech Top")}</caption>
          <thead>{tableHeaders()}</thead>
          <tbody>{tableBody(SizeGuide.sfChallengeTechTopSizes())}</tbody>
        </StyledSizeGuideWithMargin>
      )}
    </>
  );
};
export default SizeGuide;
