// eslint-disable-next-line eslint-comments/disable-enable-pair

/* eslint-disable @typescript-eslint/no-unsafe-argument */
// eslint-disable-next-line eslint-comments/disable-enable-pair

/* eslint-disable @typescript-eslint/no-unsafe-member-access */
// eslint-disable-next-line eslint-comments/disable-enable-pair

/* eslint-disable @typescript-eslint/no-unsafe-return */
import { FormikValues } from "formik";
import * as Yup from "yup";
import { T_SHIRT } from "../../constants/fields";
import { Field, FormPage } from "../../constants/journeys";
import { JourneyTypes } from "../../constants/journeys/journeyNames";
import { ActivityType } from "../../contexts/ActivityContext";
import isUkCountry from "../../utils/isUkCountry";
import { JourneyHelper } from "../../utils/journeyHelper";

const AGE_16 = "16";
const AGE_18 = "18";

export type AgeType = typeof AGE_16 | typeof AGE_18;

const nameRegExp =
  /^[a-zA-Z '‘’\-ƼƽÁáÀàȦȧÂâÄäǍǎĂăĀāÃãÅåĄąȺǠǡǺǻǞǟȀȁȂȃɐɑɒÆæǼǽǢǣƀɃƁɓƂƃƄƅĆćĊċĈĉČčÇçȻȼƇƈƆɔɕĎďĐđƋƌƊɗÐðƍȸǱǲǳǄǅǆƉɖȡÉéÈèĖėÊêËëĚěĔĕĒēĘęȨȩɆɇȄȅȆȇƐɛƎǝƏəɚɘɜɝɞȜȝƑƒǴǵĠġĜĝǦǧĞğĢģǤǥƓƔĤĥȞȟĦħƕǶıÍíÌìİÎîÏïǏǐĬĭĪīĨĩĮįƗȈȉȊȋƖĲĳȷĴĵǰɈɉɟĸǨǩĶķƘƙĹĺĿŀĽľĻļƚȽŁłƛǇǈǉȴƜŃńǸǹŇňÑñŅņƝŉƞȠǊǋǌȵŊŋÓóÒòȮȯÔôÖöǑǒŎŏŌōÕõǪǫŐőƟØøȰȱȪȫǾǿȬȭǬǭȌȍȎȏƠơƢƣŒœȢȣƤƥɊɋȹƦŔŕŘřŖŗɌɍȐȑȒȓŚśŜŝŠšŞşȘșȿƩƧƨƪßſŤťŢţƬƭƫƮȚțȾȶÞþŦŧÚúÙùÛûÜüǓǔŬŭŪūŨũŮůŲųŰűɄǗǘǛǜǙǚǕǖȔȕȖȗƯưƱƲɅŴŵƿǷÝýŶŷÿŸȲȳɎɏƳƴŹźŻżŽžƵƶȤȥɀƷǮǯƸƹƺƾɁɂ]*$/;
const emailRegExp =
  /^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@([a-z0-9]([a-z0-9-]*[a-z0-9])?\.)+[a-z][a-z]+$/i;
const excludedEmailDomains = [
  "@googlemail.co.uk",
  "@gmail.co.uk",
  "@gmail.co",
  "@gmail.con",
  "@gmail.col",
  "@gmail.comcom",
  "@gmail.coma",
  "@gmale.com",
  "@gmale.co.uk",
  "@live.go.uk",
  "@live.co.um",
  "@live.con",
  "@icloud.co",
  "@icloud.con",
  "@outlook.comm",
  "@outlook.con",
  "@outlook.xom",
  "@yahoo.co.ik",
  "@yahoo.con",
  "@hotmail.con",
  "@hotmail.co.uj",
  "@msn.con",
];
const phoneNumberRegExp = /^(0[127]|\+)[0-9 ]*$/;
const addressLine1RegExp = /^[a-zA-Z0-9\-./'& ]*$/;
const addressLine23RegExp = /^[a-zA-Z0-9\-./' ]*$/;
const numberRegExp = /^[0-9 ]*$/;
const cityRegExp = /^[a-zA-Z\-./' ]*$/;
const andRegExp = /(^and | and | and$|& | &|\+ | \+)/;
export const postcodeUkRegExp =
  /^(([gG][iI][rR] {0,}0[aA]{2})|((([a-pr-uwyzA-PR-UWYZ][a-hk-yA-HK-Y]?[0-9][0-9]?)|(([a-pr-uwyzA-PR-UWYZ][0-9][a-hjkstuwA-HJKSTUW])|([a-pr-uwyzA-PR-UWYZ][a-hk-yA-HK-Y][0-9][abehmnprv-yABEHMNPRV-Y]))) {0,}[0-9][abd-hjlnp-uw-zABD-HJLNP-UW-Z]{2}))$/i;
const postcodeIntlRegExp = /^[a-zA-Z0-9 -]+$/;
const ironOnTextRegExp = /^[a-z0-9£&@\s]+$/i;

const nameExcludedValues = [
  "anon",
  "anonymous",
  "none",
  "test",
  "undefined",
  "unknown",
];

const journeyHelper = new JourneyHelper();

function isDateValid(
  day?: string | null,
  month?: string | null,
  year?: string | null,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  min?: any,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  max?: any
) {
  if (!day || !month || !year || year.length !== 4) {
    return true;
  }

  const d = parseInt(day, 10);
  const m = parseInt(month, 10) - 1; // indexed from 0: Jan=0, Dec=11
  const y = parseInt(year, 10);

  const date = new Date(
    y,
    m,
    d,
    12, // this makes daylight savings time go away
    0
  );

  if (date > max || date < min) {
    return false;
  }

  return (
    y === date.getUTCFullYear() &&
    m === date.getUTCMonth() &&
    d === date.getUTCDate()
  );
}

function isSuporterAdult(
  selectedAgeRestriction: AgeType,
  day?: string | null,
  month?: string | null,
  year?: string | null
) {
  const cutoff = new Date();
  cutoff.setFullYear(cutoff.getFullYear() - Number(selectedAgeRestriction));
  // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
  const birthdate = new Date(`${year}-${month}-${day}`);
  return birthdate <= cutoff;
}

export const dateOfBirthDay = Yup.string()
  .trim()
  .test(
    "date-not-empty",
    "Please enter your date of birth.",
    function checkRequiredDOB(doBDD) {
      return (
        doBDD || this.parent.dateOfBirthMonth || this.parent.dateOfBirthYear
      );
    }
  )
  .test(
    "day-month",
    "Date of birth must include a day and a month.",
    function checkDOBIncludeDayMonth(doBDD) {
      return this.parent.dateOfBirthMonth || doBDD;
    }
  )
  .test(
    "day-year",
    "Date of birth must include a day and a year.",
    function checkDOBIncludeDayYear(doBDD) {
      return this.parent.dateOfBirthYear || doBDD;
    }
  )
  .required("Date of birth must include a day.")

  .test(
    "date-valid",
    "Please enter a real date.",
    function checkRealDate(doBDD) {
      return isDateValid(
        doBDD,
        this.parent.dateOfBirthMonth,
        this.parent.dateOfBirthYear,
        new Date(1900, 0),
        new Date()
      );
    }
  );

export const dateOfBirthMonth = Yup.string()
  .trim()
  .test(
    "date-not-empty",
    "Please enter your date of birth.",
    function checkRequiredDOB(doBMM) {
      return this.parent.dateOfBirthDay || doBMM || this.parent.dateOfBirthYear;
    }
  )
  .test(
    "month-year",
    "Date of birth must include a month and a year.",
    function checkDOBMonthYear(doBMM) {
      return this.parent.dateOfBirthYear || doBMM;
    }
  )
  .required("Date of birth must include a month.")
  .test(
    "date-valid",
    "Please enter a real date.",
    function checkRealDate(doBMM) {
      return isDateValid(
        this.parent.dateOfBirthDay,
        doBMM,
        this.parent.dateOfBirthYear,
        new Date(1900, 0),
        new Date()
      );
    }
  );

export const dateOfBirthYear = Yup.string()
  .trim()
  .min(4, "Year must contain 4 numbers.")
  .max(4, "Year must contain 4 numbers.")
  .test(
    "date-not-empty",
    "Please enter your date of birth.",
    function checkRequiredDOB(doBYYYY) {
      return (
        this.parent.dateOfBirthDay || this.parent.dateOfBirthMonth || doBYYYY
      );
    }
  )
  .required("Date of birth must include a year.")
  .test(
    "date-valid",
    "Please enter a real date.",
    function checkRealDate(doBYYYY) {
      return isDateValid(
        this.parent.dateOfBirthDay,
        this.parent.dateOfBirthMonth,
        doBYYYY,
        new Date(1900, 0),
        new Date()
      );
    }
  );

export const dateOfBirthYearForSFChallenge = (
  selectedAgeRestriction: AgeType
) =>
  Yup.string()
    .trim()
    .min(4, "Year must contain 4 numbers.")
    .max(4, "Year must contain 4 numbers.")
    .test(
      "date-not-empty",
      "Please enter your date of birth.",
      function checkRequiredDOB(doBYYYY) {
        return (
          this.parent.dateOfBirthDay || this.parent.dateOfBirthMonth || doBYYYY
        );
      }
    )
    .required("Date of birth must include a year.")
    .test(
      "date-valid",
      "Please enter a real date.",
      function checkRealDate(doBYYYY) {
        return isDateValid(
          this.parent.dateOfBirthDay,
          this.parent.dateOfBirthMonth,
          doBYYYY,
          new Date(1900, 0),
          new Date()
        );
      }
    )
    .test(
      "adult-valid",
      selectedAgeRestriction === AGE_16
        ? "This challenge is not suitable for supporters who are under 16 years of age."
        : "This challenge is not suitable for supporters who are under 18 years of age.",
      function checkAdult(doBYYYY) {
        return isSuporterAdult(
          selectedAgeRestriction,
          this.parent.dateOfBirthDay,
          this.parent.dateOfBirthMonth,
          doBYYYY
        );
      }
    );

export const celebrationDateDay = Yup.string()
  .trim()
  .test(
    "date-not-empty",
    "Please enter the date of your celebration.",
    // eslint-disable-next-line func-names
    function (celebrationDD) {
      return (
        celebrationDD ||
        this.parent.celebrationDateMonth ||
        this.parent.celebrationDateYear
      );
    }
  )
  .test(
    "day-month",
    "Date of your celebration must include a day and a month.",
    // eslint-disable-next-line func-names
    function (celebrationDD) {
      return this.parent.celebrationDateMonth || celebrationDD;
    }
  )
  .test(
    "day-year",
    "Date of your celebration must include a day and a year.",
    // eslint-disable-next-line func-names
    function (celebrationDD) {
      return this.parent.celebrationDateYear || celebrationDD;
    }
  )
  .required("Date of your celebration must include a day.")
  // eslint-disable-next-line func-names
  .test("date-valid", "Please enter a real date.", function (celebrationDD) {
    return isDateValid(
      celebrationDD,
      this.parent.celebrationDateMonth,
      this.parent.celebrationDateYear,
      new Date()
    );
  });

export const celebrationDateMonth = Yup.string()
  .trim()
  .test(
    "date-not-empty",
    "Please enter the date of your celebration.",
    // eslint-disable-next-line func-names
    function (celebrationDDMM) {
      return (
        this.parent.celebrationDateDay ||
        celebrationDDMM ||
        this.parent.celebrationDateYear
      );
    }
  )
  .test(
    "month-year",
    "Date of your celebration must include a month and a year.",
    // eslint-disable-next-line func-names
    function (celebrationDDMM) {
      return this.parent.celebrationDateYear || celebrationDDMM;
    }
  )
  .required("Date of your celebration must include a month.")
  // eslint-disable-next-line func-names
  .test("date-valid", "Please enter a real date.", function (celebrationDDMM) {
    return isDateValid(
      this.parent.celebrationDateDay,
      celebrationDDMM,
      this.parent.celebrationDateYear,
      new Date()
    );
  });

export const celebrationDateYear = Yup.string()
  .trim()
  .min(4, "Year must contain 4 numbers.")
  .max(4, "Year must contain 4 numbers.")
  .test(
    "date-not-empty",
    "Please enter the date of your celebration.",
    // eslint-disable-next-line func-names
    function (celebrationDDYYYY) {
      return (
        this.parent.celebrationDateDay ||
        this.parent.celebrationDateMonth ||
        celebrationDDYYYY
      );
    }
  )
  .required("Date of your celebration must include a year.")
  // eslint-disable-next-line @typescript-eslint/no-shadow
  .test(
    "date-valid",
    "Please enter a real date.",
    // eslint-disable-next-line func-names
    function (celebrationDDYYYY) {
      return isDateValid(
        this.parent.celebrationDateDay,
        this.parent.celebrationDateMonth,
        celebrationDDYYYY,
        new Date()
      );
    }
  );

export const firstName = Yup.string()
  .trim()
  .min(2, "Please enter your first name, between 2-50 characters.")
  .max(50, "Please enter your first name, between 2-50 characters.")
  .lowercase()
  .test(
    "and",
    "Please enter only one person's name.",
    (first_Name) => !first_Name?.match(andRegExp)
  )
  .notOneOf(nameExcludedValues, "Please enter a valid first name.")
  .test(
    "not-excluded-value",
    "Please enter a valid first name.",
    (first_Name) =>
      !first_Name || !nameExcludedValues.includes(first_Name.toLowerCase())
  )
  .matches(nameRegExp, "Please only use letters and hyphens.")
  .required("Please enter your first name.");

export const lastName = Yup.string()
  .trim()
  .min(2, "Please enter your last name, between 2-50 characters.")
  .max(50, "Please enter your last name, between 2-50 characters.")
  .lowercase()
  .test(
    "and",
    "Please enter only one person's name.",
    (last_Name) => !last_Name?.match(andRegExp)
  )
  .notOneOf(nameExcludedValues, "Please enter a valid last name.")
  .test(
    "not-excluded-value",
    "Please enter a valid last name.",
    (last_Name) =>
      !last_Name || !nameExcludedValues.includes(last_Name.toLowerCase())
  )
  .matches(nameRegExp, "Please only use letters and hyphens.")
  .required("Please enter your last name.");

export const emailAddress = Yup.string()
  .trim()
  .max(
    100,
    "Please enter your email address. Over 100 characters is not accepted."
  )
  .matches(emailRegExp, "Please enter a valid email address.")
  .required("Please enter your email address.")
  .test("not-excluded-email", "Please enter a valid email address.", (email) =>
    Boolean(
      email &&
        !excludedEmailDomains.find((domain) =>
          email.toLocaleLowerCase().endsWith(domain)
        )
    )
  );

export const phoneNumber = Yup.string()
  .trim()
  .min(11, "Please enter a valid phone number between 11-15 characters.")
  .max(15, "Please enter a valid phone number between 11-15 characters.")
  .matches(
    phoneNumberRegExp,
    "Please enter a valid phone number between 11-15 characters."
  );

export const phoneNumberRequired = phoneNumber.required(
  "Please enter your phone number."
);

export const country = Yup.string().required();

export const addressLine1 = Yup.string()
  .trim()
  .min(3, "Please enter your address between 3-100 characters.")
  .max(100, "Please enter your address between 3-100 characters.")
  .test(
    "not-only-number",
    "Please enter a valid address.",
    (address) => !address?.match(numberRegExp)
  )
  .matches(addressLine1RegExp, "Please enter a valid address.")
  .required("Please enter your address.")
  .test(
    "not-match-with-postcode",
    "Address line 1 should not contain a postcode",
    (address) => !address?.match(postcodeUkRegExp)
  );

export const addressLine2 = Yup.string()
  .trim()
  .max(100, "Please enter your address between 0-100 characters.")
  .test(
    "not-only-number",
    "Please enter a valid address.",
    (address) => !address?.match(numberRegExp)
  )
  .matches(addressLine23RegExp, "Please enter a valid address.");

export const addressLine3 = addressLine2;

export const city = Yup.string()
  .trim()
  .min(2, "Please enter your town between 2-50 characters.")
  .max(50, "Please enter your town. Over 50 characters is not accepted.")
  .matches(cityRegExp, "Please only use letters and the following: - ' / .")
  .required("Please enter your town or city.");

export const postcode = Yup.string()
  .trim()
  .min(2, "Please enter your postcode between 2-8 characters.")
  .max(8, "Please enter your postcode between 2-8 characters.")
  .when("country", {
    is: (countryName: string) => isUkCountry(countryName),
    then: Yup.string()
      .trim()
      .required("Please enter your postcode.")
      .matches(postcodeUkRegExp, "Please enter a valid postcode."),
    otherwise: Yup.string()
      .trim()
      .matches(postcodeIntlRegExp, "Please enter a valid postcode."),
  });

export const fundraisingTarget = Yup.number()
  .min(1, "Please enter a number between 1 and 10000000")
  .max(10000000, "Please enter a number between 1 and 10000000");

export const moreInfo = Yup.string().max(
  2000,
  "Please use less than 2000 characters."
);

export const activityType = Yup.string()
  .trim()
  .min(1, "Please enter an activity type.")
  .required("What fundraising activity are you planning? field is required.");

export const tShirtSize = (optInMerchandise: boolean) => {
  if (optInMerchandise) {
    return Yup.string().required("You must select a T-shirt size.");
  }
  return Yup.string();
};

export const tshirtVestSize = Yup.string()
  .when("tshirtOrVest", {
    is: "tshirt",
    then: Yup.string().required("You must select a size for your top."),
  })
  .when("tshirtOrVest", {
    is: "vest",
    then: Yup.string().required("You must select a size for your top."),
  });

export const maleFemaleStyles = Yup.string()
  .when("tshirtOrVest", {
    is: "tshirt",
    then: Yup.string().required("You must select a male or female style."),
  })
  .when("tshirtOrVest", {
    is: "vest",
    then: Yup.string().required("You must select a male or female style."),
  });

export const organisationName = Yup.string()
  .trim()
  .required("Organisation name field is required.")
  .min(2, "Please enter your organisation name, between 2-255 characters.")
  .max(255, "Please enter your organisation name, between 2-255 characters.");

export const eventName = Yup.string()
  .trim()
  .required("Event name is required.");

export const addressActiveOption = Yup.string().trim();

export const productQuantity = (
  productQuantityMin: number,
  productQuantityMax: number,
  productQuantityMinError: string,
  productQuantityMaxError: string
) =>
  Yup.number()
    .min(productQuantityMin, productQuantityMinError)
    .max(productQuantityMax, productQuantityMaxError)
    .required(
      "How many participants do you expect at your event? field is required."
    );

export const ironOnText = Yup.string()
  .trim()
  .matches(ironOnTextRegExp, "Please do not enter invalid characters.")
  .test({
    name: "max",
    exclusive: true,
    params: { max: 10 },
    message: `You can enter a maximum of 10 letters.`,
    test: (value) => {
      if (value) {
        if (/\s/.test(value)) {
          const removeWhiteSpaceString = value.replace(/\s+/g, "");
          if (removeWhiteSpaceString.length <= 10) {
            return true;
          }
          return false;
        }
        return !!(value.length <= 10);
      }
      return true;
    },
  });

export const haveEnteredEvent = Yup.bool().oneOf(
  [true],
  "Please confirm that you already have a place in this event by ticking the checkbox"
);

export const tshirtOrVest = Yup.string().required("Please select an option");

export const optInMerchandise = Yup.string()
  .trim()
  .oneOf(["yes", "no"], "Please select an option.");

export const hasOnlineFundraisingPage = Yup.string()
  .trim()
  .required("Please select an option.");

export const supporterNeedPack = Yup.string().required(
  "Please select an option"
);

export function makeValidationSchema(
  formConfig: FormPage,
  journey: JourneyTypes,
  activity: ActivityType,
  optInOrOutMerchandise: boolean | null
) {
  const resultSchema: FormikValues = {};

  if (formConfig.fields.includes(Field.FIRST_NAME)) {
    resultSchema.firstName = firstName;
  }

  if (formConfig.fields.includes(Field.LAST_NAME)) {
    resultSchema.lastName = lastName;
  }

  if (formConfig.fields.includes(Field.DATE_OF_BIRTH)) {
    resultSchema.dateOfBirthDay = dateOfBirthDay;
    resultSchema.dateOfBirthMonth = dateOfBirthMonth;
    resultSchema.dateOfBirthYear =
      journeyHelper.isSocialFundraisingJourney(journey) &&
      activity.ageRestriction === "yes"
        ? dateOfBirthYearForSFChallenge(
            activity.selectedAgeRestriction as AgeType
          )
        : dateOfBirthYear;
  }

  if (formConfig.fields.includes(Field.ACTIVITY_DATE)) {
    resultSchema.celebrationDateDay = celebrationDateDay;
    resultSchema.celebrationDateMonth = celebrationDateMonth;
    resultSchema.celebrationDateYear = celebrationDateYear;
  }

  // eslint-disable-next-line @typescript-eslint/no-unsafe-call
  if (formConfig.fields.includes(Field.EMAIL_ADDRESS)) {
    resultSchema.emailAddress = emailAddress;
  }

  // eslint-disable-next-line @typescript-eslint/no-unsafe-call
  if (formConfig.fields.includes(Field.PHONE_NUMBER)) {
    // phone number is optional, except for vf and lead-generation journeys
    resultSchema.phoneNumber =
      journey === "vf" ||
      journey === "vf-fundreg" ||
      journey === "lead-generation-1" ||
      journey === "gifts-in-wills-guide" ||
      journey === "sports-forms-contact-centre" ||
      journey === "sports-form"
        ? phoneNumberRequired
        : phoneNumber;
  }

  if (
    formConfig.fields.includes(Field.ADDRESS) ||
    formConfig?.secondaryFields?.includes(Field.ADDRESS)
  ) {
    resultSchema.country = country;
    resultSchema.addressLine1 = addressLine1;
    resultSchema.addressLine2 = addressLine2;
    resultSchema.addressLine3 = addressLine3;
    resultSchema.city = city;
    resultSchema.postcode = postcode;
    resultSchema.addressActiveOption = addressActiveOption;
  }

  if (formConfig.fields.includes(Field.FUNDRAISING_TARGET)) {
    resultSchema.fundraisingTarget = fundraisingTarget;
  }

  if (formConfig.fields.includes(Field.ACTIVITY_TYPE)) {
    resultSchema.activityType = activityType;
  }

  if (formConfig.fields.includes(Field.MORE_INFO)) {
    resultSchema.moreInfo = moreInfo;
  }

  if (formConfig.fields.includes(Field.T_SHIRT_SIZE)) {
    const optInTSshirt =
      (optInOrOutMerchandise && activity.merchandiseTemplateType === T_SHIRT) ||
      journey !== "sf-challenges";
    // FIXME - remove challenge requirement following results of test.
    resultSchema.tShirtSize = tShirtSize(optInTSshirt);
  }

  if (formConfig.fields.includes(Field.RADIO_SELECT_T_SHIRT_VEST_SIZE)) {
    resultSchema.tshirtVestSize = tshirtVestSize;
  }

  if (formConfig.fields.includes(Field.MALE_FEMALE_STYLES)) {
    resultSchema.maleFemaleStyles = maleFemaleStyles;
  }

  if (formConfig.fields.includes(Field.ORGANISATION_NAME)) {
    resultSchema.organisationName = organisationName;
  }

  if (formConfig.fields.includes(Field.PRODUCT_QUANTITY)) {
    resultSchema.productQuantity = productQuantity(
      parseInt(activity.productQuantityMin, 10),
      parseInt(activity.productQuantityMax, 10),
      activity.productQuantityMinError,
      activity.productQuantityMaxError
    );
  }

  if (formConfig.fields.includes(Field.IRON_ON_TEXT)) {
    resultSchema.ironOnText = ironOnText;
  }

  if (formConfig.fields.includes(Field.HAVE_ENTERED_EVENT)) {
    resultSchema.haveEnteredEvent = haveEnteredEvent;
  }

  if (formConfig.fields.includes(Field.T_SHIRT_OR_VEST)) {
    resultSchema.tshirtOrVest = tshirtOrVest;
  }

  if (formConfig.fields.includes(Field.EVENT_LOOKUP)) {
    resultSchema.eventName = eventName;
  }

  if (formConfig.fields.includes(Field.HAS_ONLINE_FUNDRAISING_PAGE)) {
    resultSchema.hasOnlineFundraisingPage = hasOnlineFundraisingPage;
  }

  if (formConfig.fields.includes(Field.SUPPORTER_NEED_PACK)) {
    resultSchema.supporterNeedPack = supporterNeedPack;
  }

  if (formConfig.fields.includes(Field.MERCHANDISE_TYPE)) {
    resultSchema.optInMerchandise = optInMerchandise;
  }

  return Yup.object().shape(resultSchema);
}
