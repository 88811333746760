// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable no-console */
const errors = {
  E0001: {
    message: "The source code does not have a journey",
    help: `The activity's sourceCode could not be mapped to a journey type.
      - Check the sourceCode in the activity fixture or API data is correct`,
  },
  E0002: {
    message: "The activity form journey does not have a form type",
    help: `The activity's form journey could not be mapped to a form type
      - Check the sourceCode and formJourney in the activity fixture or API data are correct
      - It must be matched by one of the switch cases in ActivityRoutes.tsx`,
  },
  E0003: {
    message: "The formJourney is not a known form journey value",
    help: `- Check the formJourney in the activity fixture or API data is correct
      - It must be one of the form journey values in formJourneys.tsx`,
  },
};

function logError(errorCode: keyof typeof errors, data?: unknown): void {
  console.group("%cError", "color: red;");
  console.error(`${errorCode}: ${errors[errorCode].message}`);

  if (errors[errorCode].help) {
    console.error(errors[errorCode].help);
  }

  if (data) {
    console.error(data);
  }

  console.groupEnd();
}

export default logError;
