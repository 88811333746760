import { Link, Text } from "@cruk/cruk-react-components";
import ReactMarkdown, { ReactElement } from "react-markdown";

/**
 * Renders custom text
 *
 * This function converts provided activity custom text (in markdown)
 * into it's associated react components. It is used such that
 * admins can insert custom text displays into the form. The
 * custom markdown format text handles:
 * * Line breaks
 * * Embedded links
 * * Un-ordered lists
 * * Ordered lists
 *
 * This restriction on what the user can provide was done to minimize
 * complexity and reduce the potential for activity admins to create
 * security flaws, style misalignments and broken activity pages.
 *
 * @param value custom text to render
 * @returns rendered react components
 */
export default function renderCustomText(value: string): ReactElement {
  return (
    <ReactMarkdown
      // eslint-disable-next-line react/no-children-prop
      children={value}
      allowedElements={["ol", "ul", "li", "p", "a", "br"]}
      components={{
        a: ({ node, ...props }: any) => (
          <Link target="_blank" rel="noopener noreferrer" {...props} />
        ),
        p: ({ node, ...props }: any) => <Text {...props} />,
      }}
    />
  );
}
