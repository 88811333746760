import { Box, Button } from "@cruk/cruk-react-components";
import { FormFieldProps } from "../../types";

const LegaciesDigitalGuideCTA = ({ activity, field }: FormFieldProps) =>
  activity.journey !== "gifts-in-wills-guide" ? (
    <Box marginBottom="s" key={field}>
      <Button
        appearance="primary"
        type="button"
        size="m"
        href="https://digital.cancerresearchuk.org/gifts-in-wills-guide/home/?utm_source=cruk&utm_medium=activitymanagement&utm_campaign=digitalgiwg_confirm"
      >
        View your online guide
      </Button>
    </Box>
  ) : !activity.callToActionLabel && !activity.callToActionLink ? (
    <Box marginBottom="s" key={field}>
      <Button
        appearance="primary"
        type="button"
        size="m"
        href="https://digital.cancerresearchuk.org/gifts-in-wills-guide/home/?utm_source=cruk&utm_medium=activitymanagement&utm_campaign=digitalgiwg_confirm"
      >
        View your online guide
      </Button>
    </Box>
  ) : null;

export default LegaciesDigitalGuideCTA;
