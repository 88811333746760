import { FC } from "react";
import styled from "styled-components/macro";

const Main = styled.main`
  margin: ${({ theme }) => theme.spacing.xxs} auto;
  padding: ${({ theme }) => theme.spacing.xxs};
  max-width: ${({ theme }) => theme.utilities.contentMaxWidth};
`;

const Div = styled.div`
  background-color: ${({ theme }) => theme.colors.backgroundLight};
  border: 1px solid ${({ theme }) => theme.colors.headerBorder};
  border-radius: ${({ theme }) => theme.utilities.borderRadius};
`;

const FormPageWrapper: FC = ({ children }) => (
  <Main id="main">
    <Div>{children}</Div>
  </Main>
);

export default FormPageWrapper;
