import { Box, Heading } from "@cruk/cruk-react-components";
import AddressLookupContainer from "../../containers/AddressLookupContainer";
import { RegistrationType } from "../../contexts/RegistrationContext";
import { FormFieldProps } from "../../types";

const Address = ({
  values,
  setValues,
  handleChange,
  journey,
  touched,
  errors,
  handleBlur,
  formJourneyPage,
  setFieldValue,
  submitCount,
}: FormFieldProps) => {
  const { addressHeading } = formJourneyPage;
  return (
    <>
      {addressHeading && <Heading h2>{addressHeading}</Heading>}
      <Box marginBottom="s">
        <AddressLookupContainer
          values={values as RegistrationType}
          setValues={setValues}
          handleBlur={handleBlur}
          handleChange={handleChange}
          touched={touched}
          errors={errors}
          journey={journey}
          setFieldValue={setFieldValue}
          submitCount={submitCount}
        />
      </Box>
    </>
  );
};

export default Address;
