import { FC, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Status } from "@cruk/activity-management-schema";
import {
  ACTIVITY_DATE,
  ACTIVITY_MONTH,
  CALL_TO_ACTION,
  DATE_OF_BIRTH,
  FIRST_NAME,
  FUNDRAISING_PAGE,
  LAST_NAME,
  MERCHANDISE_TYPE,
  MORE_INFO,
  ORGANISATION,
  PARTICIPANTS,
  SECOND_CALL_TO_ACTION,
  T_SHIRT,
  TARGET,
  TERMS_AND_CONDITIONS,
} from "../constants/fields";
import { hasFormJourneyConfig } from "../constants/journeys";
import ActivityContext, { ActivityType } from "../contexts/ActivityContext";
import useDataLayer from "../hooks/useDataLayer";
import { getActivityByUrl } from "../utils/api/client";
import { trackDataDogError } from "../utils/dataDog";
import { FieldUtils } from "../utils/fieldUtils";
import logError from "../utils/logError";
import { sanitize } from "dompurify";

type ActivityContainerProps = {
  isErrorPage: boolean;
};

const ActivityContainer: FC<ActivityContainerProps> = ({
  isErrorPage,
  children,
}) => {
  const navigate = useNavigate();
  const [activity, setActivity] = useState<ActivityType>();
  const { urlName } = useParams<{ urlName: string }>();
  // eslint-disable-next-line @typescript-eslint/unbound-method
  const { pushDataLayerLoaded } = useDataLayer();
  const {
    location: { search },
  } = window;
  const params = new URLSearchParams(search);
  const eventCode = sanitize(params.get("event_code") as string);

  useEffect(() => {
    async function fetchActivityEffect() {
      try {
        const response = await getActivityByUrl(urlName!);
        // eslint-disable-next-line no-underscore-dangle
        switch (response.__typename) {
          case "Activity":
            // FIXME - Remove `ActivityType` and use the schema as-is without transforming it.
            {
              const fieldUtils = new FieldUtils(response.journeyConfig.fields);
              setActivity({
                activityMonthDateHeader:
                  fieldUtils.get(ACTIVITY_MONTH)?.header ?? "",
                activityType: response.type,
                activityName: response.name,
                activityStatus:
                  response.status === Status.Open ? "active" : "closed",
                activitySuccessHeader: response.successHeader ?? "",
                activitySuccessMessage: response.successMessage ?? "",
                activityDateHeader: fieldUtils.get(ACTIVITY_DATE)?.header ?? "",
                activityDateHelpText:
                  fieldUtils.get(ACTIVITY_DATE)?.helpText ?? "",
                callToActionLabel: fieldUtils.get(CALL_TO_ACTION)?.header ?? "",
                callToActionLink: fieldUtils.get(CALL_TO_ACTION)?.text ?? "",
                secondCallToActionLabel:
                  fieldUtils.get(SECOND_CALL_TO_ACTION)?.header ?? "",
                secondCallToActionLink:
                  fieldUtils.get(SECOND_CALL_TO_ACTION)?.text ?? "",
                dobHelpText: fieldUtils.get(DATE_OF_BIRTH)?.helpText ?? "",
                firstNameLabel: fieldUtils.get(FIRST_NAME)?.header ?? "",
                fundraisingPageHeading:
                  fieldUtils.get(FUNDRAISING_PAGE)?.header ||
                  "Your fundraising target",
                fundraisingTargetLabel: fieldUtils.get(TARGET)?.header ?? "",
                fundraisingTargetHelpTextHtml:
                  fieldUtils.get(TARGET)?.helpText ?? "",
                bannerText: response.journeyConfig.bannerText ?? "",
                header: response.journeyConfig.title ?? "",
                introTextHtml: response.journeyConfig.introText ?? "",
                id: response.id,
                journey: response.journeyConfig.journey,
                loaded: true,
                lastNameLabel: fieldUtils.get(LAST_NAME)?.header ?? "",
                moreInfoHeader: fieldUtils.get(MORE_INFO)?.header ?? "",
                moreInfoHelpText: fieldUtils.get(MORE_INFO)?.helpText ?? "",
                merchandiseTemplateType: response.merchandiseTemplateType ?? "",
                ageRestriction: response.ageRestriction ?? "",
                selectedAgeRestriction: response.selectedAgeRestriction ?? "",
                merchandiseLabelHeader:
                  fieldUtils.get(MERCHANDISE_TYPE)?.header ?? "",
                organisationName: fieldUtils.get(ORGANISATION)?.header ?? "",
                productQuantityHeader:
                  fieldUtils.get(PARTICIPANTS)?.header ?? "",
                productQuantityHelpText:
                  fieldUtils.get(PARTICIPANTS)?.helpText ?? "",
                productQuantityMax:
                  fieldUtils
                    .get(PARTICIPANTS)
                    ?.validation?.maxValue?.toString() ?? "",
                productQuantityMin:
                  fieldUtils
                    .get(PARTICIPANTS)
                    ?.validation?.minValue?.toString() ?? "",
                productQuantityMaxError:
                  fieldUtils.get(PARTICIPANTS)?.validation
                    ?.maxValueErrorMessage ?? "",
                productQuantityMinError:
                  fieldUtils.get(PARTICIPANTS)?.validation
                    ?.minValueErrorMessage ?? "",
                productCode: response.reference.productCode,
                products: response.products.map((product) => ({
                  productCode: product.code,
                  enabled: product.enabled ? "Y" : "N",
                  type: product.type as "pack" | "tshirt",
                  precedence: product.precedence,
                  metaData: product.metadata,
                })),
                sourceCode: response.reference.sourceCode,
                submitButtonLabel:
                  response.journeyConfig.submitButtonLabel ?? "",
                tShirtHeader: fieldUtils.get(T_SHIRT)?.header ?? "",
                termsAndConditionsHeader:
                  fieldUtils.get(TERMS_AND_CONDITIONS)?.header ?? "",
                termsAndConditionsTextHtml:
                  fieldUtils.get(TERMS_AND_CONDITIONS)?.text ?? "",
                typeConfig: response.journeyConfig.validActivityTypes.reduce(
                  (o, key) => ({ ...o, [key]: key }),
                  {}
                ),
                theme: response.journeyConfig.theme.toLowerCase() as
                  | "cruk"
                  | "rfl"
                  | "su2c",
                urlName: response.url,
                metaTitle: response.metaTitle ?? "",
                metaDescription: response.metaDescription ?? "",
              });
            }
            return;
          default:
            trackDataDogError(`Unsuccessful retrieval of activity ${urlName}`);

            throw new Error(`Unsuccessful retrieval of activity ${urlName}`);
        }
      } catch (err) {
        trackDataDogError("", { error: err });
        // eslint-disable-next-line no-console
        console.error(err);
        navigate("/error");
      }
      return;
    }

    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    fetchActivityEffect();
  }, [navigate, urlName]);

  useEffect(() => {
    if (!activity?.loaded) {
      return;
    }

    // redirect to new DIY form
    if (urlName === "fundraising-reg-pack-info") {
      navigate("/diy-inspire-fundraising-pack");
    }

    if (!activity.journey) {
      trackDataDogError("No activity journey", {
        sourceCode: activity.sourceCode,
      });
      logError("E0001", { sourceCode: activity.sourceCode });
      navigate("/error");
      return;
    }

    if (!hasFormJourneyConfig(activity.journey)) {
      logError("E0002", { journey: activity.journey });
      navigate("/error");
      return;
    }

    // Specific for sports external journey
    pushDataLayerLoaded(activity, eventCode);

    if (activity.activityStatus !== "active" && !isErrorPage) {
      navigate(`/${activity.urlName}/error/closed`);
    }
  });

  if (!activity?.loaded) {
    return null;
  }

  return (
    <ActivityContext.Provider value={activity}>
      {children}
    </ActivityContext.Provider>
  );
};

export default ActivityContainer;
