// We define these constants such that these strings can be kept in sync across the codebase.
export const FIRST_NAME = "first_name";
export const LAST_NAME = "last_name";
export const DATE_OF_BIRTH = "date_of_birth";
export const TARGET = "target";
export const ACTIVITY_MONTH = "activity_month";
export const ACTIVITY_DATE = "activity_date";
export const ACTIVITY_TYPE = "activity_type";
export const MORE_INFO = "more_info";
export const ORGANISATION = "organisation";
export const PARTICIPANTS = "participants";
export const TERMS_AND_CONDITIONS = "terms_and_conditions";
export const CALL_TO_ACTION = "call_to_action";
export const SECOND_CALL_TO_ACTION = "second_call_to_action";
export const T_SHIRT = "tshirt";
export const OTHER_MERCHANDISE = "other";
export const FUNDRAISING_PAGE = "fundraising_page";
export const MERCHANDISE_TYPE = "merchandise_type";
export const AGE_RESTRICTION = "age_restriction";
export const MALE = "male";
export const FEMALE = "female";
