import { Box, TextField } from "@cruk/cruk-react-components";
import { FormFieldProps } from "../../types";
import renderCustomText from "../../utils/renderCustomText";

const ProductQuantity = ({
  field,
  activity,
  handleBlur,
  filterHandleChange,
  isRequired,
  touched,
  errors,
  values,
}: FormFieldProps) => (
  <Box marginBottom="s" key={field}>
    <TextField
      label={activity.productQuantityHeader}
      type="text"
      inputMode="numeric"
      pattern="[0-9]*"
      hintText={<>{renderCustomText(activity.productQuantityHelpText)}</>}
      name="productQuantity"
      required={isRequired("productQuantity")}
      value={values.productQuantity as string}
      onChange={filterHandleChange()}
      onBlur={handleBlur}
      errorMessage={
        !!touched.productQuantity && !!errors.productQuantity
          ? errors.productQuantity
          : ""
      }
    />
  </Box>
);

export default ProductQuantity;
