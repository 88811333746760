import { useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import getFormJourney from "../constants/journeys/formJourney";
import ActivityContext from "../contexts/ActivityContext";
import RegistrationContext, {
  RegistrationType,
} from "../contexts/RegistrationContext";
import { trackDataDogError } from "../utils/dataDog";
import JourneyHelper from "../utils/journeyHelper";
import useDataLayer from "./useDataLayer";

// Custom hook that returns an object of nextPage() and previousPage() functions to
// switch between pages of the form.
//
// The returned functions take an object of the current form
// values from Formik and store them in the registration state, then do a
// navigate() to the route of the new page.
//
// Example:
// const PageComponent = () => {
//   const { nextPage, previousPage } = useNavigateToPage("address");
//   const { registration } = useContext(RegistrationContext);
//
//   const newRegistration = { ...registration, firstName: "spongebob" };
//
//   return (
//     <>
//       <button onClick={() => nextPage(newRegistration)}>
//         Go to next page
//       </button>
//       <button onClick={() => previousPage(newRegistration)}>
//         Go to previous page
//       </button>
//     </>
//   );
// };
function useNavigateToPage(journey: string, currentPage: string) {
  const navigate = useNavigate();
  const location = useLocation();
  const activity = useContext(ActivityContext);
  const { setRegistration } = useContext(RegistrationContext);
  // eslint-disable-next-line @typescript-eslint/unbound-method
  const { pushPageView } = useDataLayer();
  const { isSportsFormWithEventCodeJourney } = new JourneyHelper();

  function navigateToPage(
    values: RegistrationType,
    indexFunction: (index: number) => number
  ) {
    const formJourney = getFormJourney(journey, activity);
    // This uses the fact that key traversal is predictable in JavaScript for
    // string keys. Should this change a map or list could be used instead.
    const pages = Object.keys(formJourney.pages);

    const currentPageIndex = pages.indexOf(currentPage);
    const nextPageIndex = indexFunction(currentPageIndex);

    if (nextPageIndex >= pages.length || nextPageIndex < 0) {
      trackDataDogError("Attempted to navigate to an undefined page.");
      throw new Error("Attempted to navigate to an undefined page.");
    } else {
      const formConfigEntry = formJourney.pages[pages[nextPageIndex]];
      const rootPath = formConfigEntry.path === "/";
      const nextPath = rootPath ? `/${activity.urlName}` : formConfigEntry.path;
      const eventCode = location.search;
      const href = `${window.location.origin}/${activity.urlName}/${nextPath}`;

      setRegistration(values);

      if (
        nextPath.endsWith("thank-you") &&
        isSportsFormWithEventCodeJourney(activity.journey)
      ) {
        window.location.href = href + eventCode;
        return;
      } else if (nextPath.endsWith("thank-you")) {
        window.location.href = href;
        return;
      }

      if (
        isSportsFormWithEventCodeJourney(activity.journey) &&
        location.pathname.match(`/${activity.urlName}`)
      ) {
        navigate(nextPath + eventCode);
      } else {
        navigate(nextPath);
      }

      // Note: must do this after navigate() so useDataLayer() will see the new
      // value of window.location.pathname
      const pageTitle = legacyPageTitleTransform(formConfigEntry.heading);
      pushPageView(pageTitle);
    }
  }

  // This function is required to maintain previous behaviour with existing page
  // titles. These titles are passed to Google Tag Manager for analytics. The previous
  // implementation used the entries listed below instead of the form header in certain
  // scenarios. In most cases the header matches except for the 6 listed below.
  function legacyPageTitleTransform(pageTitle: string) {
    switch (pageTitle) {
      case "Staying in touch with us":
        return "Join us";
      case "Personal details":
      case "Your details":
        return "Contact details";
      case "Challenge details":
      case "Your fundraising":
        return "Your fundraising target";
      case "Your organisation":
        return "Your address";
      // no default
    }
    return pageTitle;
  }

  function nextPage(values: RegistrationType) {
    navigateToPage(values, (i) => i + 1);
  }

  function previousPage(values: RegistrationType) {
    navigateToPage(values, (i) => i - 1);
  }

  return {
    nextPage,
    previousPage,
  };
}

export default useNavigateToPage;
