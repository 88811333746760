import { Box, Select } from "@cruk/cruk-react-components";
import { ChangeEvent } from "react";
import { RegistrationType } from "../../contexts/RegistrationContext";
import { FormFieldProps } from "../../types";
import JourneyHelper from "../../utils/journeyHelper";

const { isDIYJourney } = new JourneyHelper();

const ActivityType = ({
  values,
  field,
  touched,
  errors,
  getFieldProps,
  handleChange,
  setValues,
  activity,
  isRequired,
  journey,
}: FormFieldProps) => (
  <Box marginBottom="s" key={field}>
    <Select
      label={
        isDIYJourney(journey)
          ? "Fundraising activity"
          : "What fundraising activity are you planning?"
      }
      {...getFieldProps("activityType")}
      required={isRequired("activityType") && !isDIYJourney(journey)}
      onChange={(event: ChangeEvent<HTMLSelectElement>) => {
        setValues({ ...(values as RegistrationType) });
        handleChange(event);
      }}
      errorMessage={touched.activityType ? errors.activityType : undefined}
    >
      <option disabled value="">
        Please select
      </option>
      {Object.entries(activity.typeConfig).map(([label, value]) => (
        <option value={value} key={value}>
          {label}
        </option>
      ))}
    </Select>
  </Box>
);

export default ActivityType;
