import { ActivityRegistrationSuccess } from "@cruk/activity-management-schema";
import { PACK, T_SHIRT, VEST } from "../constants/productTypes";
import { ActivityType } from "../contexts/ActivityContext";
import { RegistrationType } from "../contexts/RegistrationContext";
import { submitRegistration } from "./api/client";
import { conversionInput } from "./conversionInput";
import { cookieBannerInput } from "./cookieBannerInput";
import isUkCountry from "./isUkCountry";
import { JourneyHelper } from "./journeyHelper";
import transform from "./transform";

const createActivityTypeSection = (
  activity: ActivityType,
  registration: RegistrationType
) => ({
  activityType: registration.activityType || activity.activityType || undefined,
});

const createTelephoneNumberSection = (country: string, phoneNumber: string) =>
  isUkCountry(country) && phoneNumber.indexOf("07") === 0
    ? { telephoneNumberMobile: phoneNumber }
    : { telephoneNumberLandline: phoneNumber };

export async function createProcessJob(
  activity: ActivityType,
  registration: RegistrationType
): Promise<ActivityRegistrationSuccess> {
  const transformedRegistration = transform(registration);
  const journeyHelper = new JourneyHelper();

  const activityTypeSection = createActivityTypeSection(
    activity,
    transformedRegistration
  );
  const optInSocialFundrasingMerchandise =
    transformedRegistration.optInMerchandise === "yes";
  const telephoneNumberSection = createTelephoneNumberSection(
    transformedRegistration.country,
    transformedRegistration.phoneNumber
  );

  const { products } = activity;

  const quantity =
    transformedRegistration.productQuantity === ""
      ? "1"
      : transformedRegistration.productQuantity;

  const getDeliveryNote = () => {
    if (
      journeyHelper.isSocialFundraisingJourney(activity.journey) &&
      activity.merchandiseTemplateType === "other" &&
      registration.optInMerchandise === "no"
    ) {
      return "NO PACK";
    }
    if (journeyHelper.isSportsFormJourney(activity.journey)) {
      if (registration.supporterNeedPack === "no") {
        return `NO PACK,${
          registration.contactCentreSportsProductLookUp.length > 0
            ? `,${registration.contactCentreSportsProductLookUp.toString()}`
            : ""
        }`;
      }
      return registration.contactCentreSportsProductLookUp.toString();
    }
    return "";
  };

  const generateAttributes = (product: {
    productCode: string;
    enabled: "Y" | "N";
    type: "pack" | "tshirt" | "vest";
    metaData: { key: string; value: string }[];
    precedence: number;
  }) => {
    if (journeyHelper.isSportsFormJourney(activity.journey)) {
      if (product.type === T_SHIRT || product.type === VEST) {
        return [
          {
            letters: registration.ironOnText,
          },
        ];
      }
      if (product.type === PACK) {
        return [
          { "Event Code": registration?.eventCode },
          {
            "Event Date": registration?.eventStart
              ? `${new Date(registration.eventStart).getFullYear()}-${String(
                  new Date(registration.eventStart).getMonth() + 1
                ).padStart(2, "0")}-${String(
                  new Date(registration.eventStart).getDate()
                ).padStart(2, "0")}`
              : undefined,
          },
          { "Event Name": registration.eventName },
          {
            "Activity Type": registration?.eventTypes
              ? registration?.eventTypes[0]
              : undefined,
          },
          {
            "Pledge Amount": registration.fundraisingTarget
              ? parseInt(registration.fundraisingTarget, 10).toString()
              : "0.00",
          },
        ];
      }
    } else if (product.type === T_SHIRT) {
      return [{ letters: "FACEBOOK" }];
    } else if (product.productCode.startsWith("FB")) {
      return [
        { "Event Name": activity.activityName },
        { "Event Code": activity.sourceCode },
        { "Event Type": activity.activityType },
      ];
    }
    return undefined;
  };

  const createOrderLines = () =>
    products
      .filter(
        (product) =>
          product.enabled === "Y" &&
          (product.type === PACK ||
            ((product.type === T_SHIRT || product.type === VEST) && // For sports form and generic forms which only have t-shirts
              (product.productCode === registration.tShirtSize ||
                product.productCode === registration.tshirtVestSize)) ||
            (activity.merchandiseTemplateType === T_SHIRT && // For Social Fundraising Challenge
              optInSocialFundrasingMerchandise &&
              registration.tShirtSize === product.productCode))
      )
      .map((product, index) => ({
        lineNumber: index + 1,
        quantity,
        productCode: product.productCode,
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-explicit-any
        attributes: generateAttributes(product) as any,
      }));

  const createFulfilmentSection = () => {
    const orderLines = createOrderLines();
    const deliveryNote = getDeliveryNote();
    const fulfilment = {
      orderLines: orderLines.map((orderLine) => {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        const attributes =
          // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
          orderLine.attributes && orderLine.attributes.length > 0
            ? // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
              orderLine.attributes.flatMap((attribute: any) =>
                // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
                Object.keys(attribute).map((key) => ({
                  name: key,
                  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
                  value: attribute[key],
                }))
              )
            : [];
        if (transformedRegistration.activityDateTime) {
          const eventDate = new Date(transformedRegistration.activityDateTime);
          // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
          attributes.push({
            name: "Event Date",
            value: `${eventDate.getFullYear()}-${String(
              eventDate.getMonth() + 1
            ).padStart(2, "0")}-${String(eventDate.getDate()).padStart(
              2,
              "0"
            )}`,
          });
        }
        return {
          lineNumber: orderLine.lineNumber,
          quantity: parseInt(orderLine.quantity, 10),
          productCode: orderLine.productCode,
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
          attributes,
        };
      }),
      productQuantity: journeyHelper.isSportsFormJourney(activity.journey)
        ? parseInt(quantity, 10)
        : +quantity,
      deliveryMethod: transformedRegistration.deliveryMethod ?? "post",
      ...(journeyHelper.isSportsFormJourney(activity.journey)
        ? { eventCode: registration.eventCode }
        : {}),
      ...(deliveryNote ? { deliveryNote } : {}),
    };
    return { fulfilment };
  };

  const isSocialFundraisingJourneyWithoutMerchandise =
    journeyHelper.isSocialFundraisingJourney(activity.journey) &&
    !optInSocialFundrasingMerchandise;

  const shouldSkipFulfilmentData = isSocialFundraisingJourneyWithoutMerchandise; // Add Condition here if you want to skip sending fulfilment object

  const getFulfilment = () => {
    if (shouldSkipFulfilmentData) {
      return {};
    }
    return createFulfilmentSection();
  };

  const restrictionSection = {
    activityResCode:
      transformedRegistration.restriction === "yes" &&
      transformedRegistration.restrictionCode !== ""
        ? transformedRegistration.restrictionCode
        : undefined,
  };

  const suppressionCodes = {
    email: { yes: "PYEM", no: "PNEM", unknown: "PUEM" },
    text: { yes: "PYSM", no: "PNSM", unknown: "PUSM" },
    post: { yes: "LIPO", no: "PNPO", unknown: "LIPO" },
    phone: { yes: "LIPH", no: "PNPH", unknown: "LIPH" },
  };

  const suppressionsSection = {
    suppressions: [
      suppressionCodes.email[transformedRegistration.optInEmail],
      suppressionCodes.post[transformedRegistration.optInPost],
      suppressionCodes.phone[transformedRegistration.optInPhone],
      suppressionCodes.text[transformedRegistration.optInText],
    ],
  };

  const registrationData = {
    activityId: activity.id,
    ...activityTypeSection,
    ...getFulfilment(),
    // FIXME - When the previous backend integration is removed this can be simplified such that time is in the ISO 8601 date and time format everywhere.
    activityDateTime: transformedRegistration.activityDateTime
      ? transformedRegistration.activityDateTime.replace("Z", ".000Z")
      : undefined,
    activityRestrictionCode: restrictionSection.activityResCode || undefined,
    fundraisingTarget:
      parseInt(registration.fundraisingTarget, 10) || undefined,
    moreInfo:
      transformedRegistration.moreInfo ||
      (transformedRegistration.informationLookingFor &&
      transformedRegistration.informationLookingFor === "Other"
        ? transformedRegistration.informationLookingForFreeText
        : transformedRegistration.informationLookingFor) ||
      undefined,
    organisationName: transformedRegistration.organisationName || undefined,
    hasOnlineFundraisingPage:
      registration.hasOnlineFundraisingPage || undefined,
    eventCode: registration.eventCode || undefined,
  };

  const activityFundraiserData = {
    emailAddress: transformedRegistration.emailAddress,
    dateOfBirth:
      transformedRegistration.dateOfBirthDay &&
      transformedRegistration.dateOfBirthMonth &&
      transformedRegistration.dateOfBirthYear
        ? `${transformedRegistration.dateOfBirthYear}-${String(
            transformedRegistration.dateOfBirthMonth
          ).padStart(2, "0")}-${String(
            transformedRegistration.dateOfBirthDay
          ).padStart(2, "0")}`
        : undefined,
    forename: transformedRegistration.firstName,
    surname: transformedRegistration.lastName,
    address: {
      city: transformedRegistration.city,
      country: transformedRegistration.country,
      line1: transformedRegistration.addressLine1,
      line2: transformedRegistration.addressLine2 || undefined,
      line3: transformedRegistration.addressLine3 || undefined,
      postcode: transformedRegistration.postcode,
      validated: transformedRegistration.validated,
    },
    landlineNumber: telephoneNumberSection.telephoneNumberLandline || undefined,
    mobileNumber: telephoneNumberSection.telephoneNumberMobile || undefined,
    suppressions: suppressionsSection.suppressions,
  };

  const conversionData = conversionInput(activity);

  return submitRegistration({
    registration: { ...registrationData },
    fundraiser: { ...activityFundraiserData },
    conversion: conversionData,
    cookieBanner: cookieBannerInput(),
  });
}
