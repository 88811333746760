import { Box, IconFa, Link, Text } from "@cruk/cruk-react-components";
import { faEnvelope, faTruck } from "@fortawesome/free-solid-svg-icons";
import ImageBox from "../../components/ImageBox";
import defaultSportsImage from "../../assets/sports-forms-images/default_miguel-a-amutio-Y0woUmyxGrw-unsplash.jpg";
import { FormFieldProps } from "../../types";

const SportsFormsCTA = ({ field }: FormFieldProps) => (
  <>
    <Box marginBottom="s" key={field}>
      <Text textSize="l">Thank you for supporting us!</Text>
      <ImageBox
        alt="Diplays a sports fundraising pack for running and other events"
        src={defaultSportsImage}
      />
      <Text textSize="l">What happens next?</Text>
      <Box style={{ display: "flex" }} marginBottom="xs">
        <Box marginBottom="none" marginRight="xs">
          <IconFa
            faIcon={faEnvelope}
            color="#2E008B"
            size="1.5rem"
            aria-label="emailIcon"
          />
        </Box>
        <Box marginBottom="none">
          Check your inbox - we have sent you an email confirmation.
        </Box>
      </Box>
      <Box style={{ display: "flex" }} marginBottom="none">
        <Box marginBottom="none" marginRight="xs">
          <IconFa
            faIcon={faTruck}
            color="#2E008B"
            size="1.5rem"
            aria-label="addressIcon"
          />
        </Box>
        <Box marginBottom="none">
          Expect your fundraising pack to arrive by post in the next
          <strong> two weeks</strong>
        </Box>
      </Box>
    </Box>
    <Box marginBottom="s">
      <Text>
        If you have any questions, you can email us at{" "}
        <Link
          rel="nofollow"
          appearance="secondary"
          aria-label="If you click the email link you will be taken to the email supporter.services@cancer.org.uk"
          href="mailto:supporter.services@cancer.org.uk"
        >
          {" "}
          supporter.services@cancer.org.uk
        </Link>{" "}
        or call us on{" "}
        <Link
          rel="nofollow"
          href="tel:0300 123 5461"
          appearance="secondary"
          aria-label="If you click on this telephone link you can phone 0300 123 3379"
        >
          0300 123 5461
        </Link>{" "}
        (we are here Monday to Friday 9:00am to 6:00pm in the UK).
      </Text>
    </Box>
  </>
);

export default SportsFormsCTA;
