import { Box, Button } from "@cruk/cruk-react-components";
import { FormFieldProps } from "../../types";

const FacebookFundraiserCTA = ({ activity, field }: FormFieldProps) =>
  !activity.callToActionLabel && !activity.callToActionLink ? (
    <Box marginBottom="s" key={field}>
      <Button
        appearance="primary"
        size="l"
        href="https://www.facebook.com/fund/cancerresearchuk/"
      >
        Set up your Facebook Fundraiser
      </Button>
    </Box>
  ) : null;

export default FacebookFundraiserCTA;
