import { datadogRum } from "@datadog/browser-rum";
import { version } from "../../package.json";

declare global {
  interface Window {
    datadogRum: typeof datadogRum;
  }
}

const ENV_NAME =
  (process.env.REACT_APP_DATA_DOG_ENV?.toLowerCase() as
    | "production"
    | "integration"
    | "development") || "";

const extractUrlParts = (
  url: string
): { protocol: string; subdomains: string; tld: string } | null => {
  const regex = /^(https?):\/\/((?:[\w-]+\.)+)([\w-]+\.[\w-]+)(\/.*)?$/;
  const match = url.match(regex);

  if (match) {
    const subdomains = match[2].slice(0, -1);
    return {
      protocol: match[1],
      subdomains: subdomains,
      tld: match[3],
    };
  }

  return null;
};

export const setUpDataDog = (graphQLEndpoint: string): void => {
  if (ENV_NAME === "development") return;

  const getUrlParts = extractUrlParts(graphQLEndpoint);

  datadogRum.init({
    applicationId: "cb0074c1-646b-4100-a1bf-29d9a26573fc",
    clientToken: "pubb1e870fb63983e62c484468955bc710e",
    site: "datadoghq.eu",
    service: "activity-management",
    env: ENV_NAME,
    allowedTracingUrls:
      ENV_NAME === "production"
        ? [
            `${graphQLEndpoint}`,
            `/${getUrlParts?.protocol}:\/\/.*\.${getUrlParts?.subdomains}.\.${getUrlParts?.tld}/`,
            (url) => url.startsWith(`${graphQLEndpoint}`),
          ]
        : [],
    // Specify a version number to identify the deployed version of your application in Datadog
    version,
    sessionSampleRate: 100,
    sessionReplaySampleRate: ENV_NAME === "production" ? 10 : 0,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: "mask-user-input",
  });

  datadogRum.startSessionReplayRecording();
};

export const trackDataDogError = (errorMessage: string, extraInfo?: object) => {
  if (ENV_NAME === "development") return;

  datadogRum.addError(errorMessage, extraInfo);
};
