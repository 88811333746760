import {
  crukTheme as originalCrukTheme,
  su2cTheme as originalSU2CTheme,
} from "@cruk/cruk-react-components";
import { FC } from "react";
import { Helmet } from "react-helmet";
import deepmerge from "deepmerge";
import { ThemeProvider as StyledThemeProvider } from "styled-components";
import standardLifeLogo from "../assets/rfl_standard_life_logo_placeholder.png";

const crukTheme = deepmerge(
  originalCrukTheme,
  {
    siteConfig: {
      logoAlt: "Cancer Research UK",
      logoUrl: "https://www.cancerresearchuk.org",
      title: "Cancer Research UK",
      faviconUrl: "/favicon.ico",
    },
    colors: {
      circularProgress: originalCrukTheme.tokenColors.magenta_500,
      progressBar: originalCrukTheme.tokenColors.darkBlue_500,
    },
    buttonFontWeight: originalCrukTheme.typography.fontWeightMedium,
    headingFontWeight: originalCrukTheme.typography.fontWeightMedium,
    headingTextTransform: originalCrukTheme.button.textTransform,
  },
  {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    arrayMerge: (_, array) => array,
  }
);

const rflTheme = deepmerge(
  crukTheme,
  {
    siteConfig: {
      logoSrc: standardLifeLogo,
      logoAlt: "Race for Life",
      logoUrl: "https://raceforlife.cancerresearchuk.org",
      title: "Race for Life",
    },
  },
  {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    arrayMerge: (_, array) => array,
  }
);

const su2cTheme = deepmerge(
  originalSU2CTheme,
  {
    siteConfig: {
      logoUrl: "https://standuptocancer.org.uk/",
      title: "Stand Up To Cancer",
      faviconUrl: "/favicon-su2c.ico",
    },
    colors: {
      circularProgress: originalSU2CTheme.tokenColors.su2cOrange3,
      progressBar: originalSU2CTheme.tokenColors.su2cBlack,
      textInputBorder: originalSU2CTheme.tokenColors.su2cBlack,
    },
    buttonFontWeight: originalSU2CTheme.typography.fontWeightHeavy,
    headingFontWeight: originalSU2CTheme.typography.fontWeightHeavy,
    headingTextTransform: originalSU2CTheme.button.textTransform,
    faviconUrl: "/favicon-su2c.ico",
  },
  {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    arrayMerge: (_, array) => array,
  }
);

export const themes = {
  cruk: crukTheme,
  rfl: rflTheme,
  su2c: su2cTheme,
  dry: crukTheme,
};

type ThemeProviderProps = {
  themeKey: keyof typeof themes;
};

const ThemeProvider: FC<ThemeProviderProps> = ({
  themeKey = "cruk",
  children,
}) => {
  const theme = themes[themeKey];
  const {
    siteConfig: { faviconUrl },
  } = theme;

  return (
    <StyledThemeProvider theme={theme}>
      <Helmet>
        <link rel="icon" href={faviconUrl} />
      </Helmet>
      {children}
    </StyledThemeProvider>
  );
};

export default ThemeProvider;
