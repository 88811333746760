import { Box } from "@cruk/cruk-react-components";
import { FunctionComponent } from "react";
import { CSSProperties } from "styled-components";

type ImageBoxProps = {
  alt: string;
  src: string;
  style?: CSSProperties;
};

const ImageBox: FunctionComponent<ImageBoxProps> = ({ alt, src, style }) => (
  <Box>
    <img alt={alt} src={src} style={style} />
  </Box>
);
export default ImageBox;
