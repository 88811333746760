// eslint-disable-next-line eslint-comments/disable-enable-pair

/* eslint-disable eslint-comments/no-duplicate-disable */

/* eslint-disable eslint-comments/disable-enable-pair */

/* eslint-disable @typescript-eslint/no-unsafe-member-access */

/* eslint-disable eslint-comments/disable-enable-pair */

/* eslint-disable @typescript-eslint/no-unsafe-return */
import { Text } from "@cruk/cruk-react-components";
import { FC } from "react";
import styled from "styled-components/macro";

type StepTrackerProps = {
  currentStep: number;
  totalSteps: number;
};

const StepTrackerWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 40px;
  height: 40px;

  @media (min-width: ${({ theme }) => theme.breakpoint.desktop}) {
    width: 80px;
    height: 80px;
  }
`;

const StyledText = styled(Text)<any>`
  color: ${({ theme }) => theme.colors.progressBar};
  @media (min-width: ${({ theme }) => theme.breakpoint.desktop}) {
    font-size: ${({ theme }) => theme.fontSizes.l};
  }
`;

const StepTrackerSvg = styled.svg`
  position: absolute;
  transform: rotate(-90deg);
  fill: none;
  stroke-linecap: round;

  width: 40px;
  height: 40px;

  @media (min-width: ${({ theme }) => theme.breakpoint.desktop}) {
    width: 80px;
    height: 80px;
  }

  .empty {
    stroke: ${({ theme }) => theme.tokenColors.grey_200};
  }

  .full {
    stroke: ${({ theme }) => theme.colors.circularProgress};
  }
`;

const StepTracker: FC<StepTrackerProps> = ({
  currentStep,
  totalSteps,
  ...props
}) => {
  const r = 16;
  const strokeWidth = 4;
  const d = (r + strokeWidth) * 2;

  return (
    <StepTrackerWrapper>
      <StyledText
        forwardedAs="span"
        textSize="s"
        textWeight={700}
        role="progressbar"
        aria-valuenow={currentStep}
        aria-valuemin={0}
        aria-valuetext={`Step ${currentStep} of ${totalSteps}`}
        aria-valuemax={totalSteps}
        {...props}
      >{`${currentStep}/${totalSteps}`}</StyledText>
      <StepTrackerSvg width={d} height={d} viewBox={`0 0 ${d} ${d}`}>
        <circle
          className="empty"
          cx={r + strokeWidth}
          cy={r + strokeWidth}
          r={r}
          strokeWidth={strokeWidth}
        />
        <circle
          className="full"
          cx={r + strokeWidth}
          cy={r + strokeWidth}
          r={r}
          strokeWidth={strokeWidth}
          strokeDasharray={2 * Math.PI * r}
          strokeDashoffset={2 * Math.PI * r * (1 - currentStep / totalSteps)}
        />
      </StepTrackerSvg>
    </StepTrackerWrapper>
  );
};

export default StepTracker;
