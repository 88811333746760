export enum FeatureFlags {
  DEVELOPMENT_EXPERIMENT_FLAG = "development_experiement_flag",
  GIWG_ADDITIONAL_QUESTION = "giwg_additional_question",
}

export enum FeatureFlagVariation {
  CONTROL = "control",
  VARIATION = "variation",
  ON = "on",
  OFF = "off",
}

export enum Events {
  GIWG_SIGNUP_EVENT = "giwg_signup_event",
  GIWG_INFORMATION_LOOKING_FOR = "giwg_information_looking_for",
}

export enum FeatureFlagPathname {
  GIWG = "gifts-in-wills-guide",
}
