import {
  OptimizelyDecideOption,
  OptimizelyDecision,
  OptimizelyUserContext,
} from "@optimizely/optimizely-sdk";
import { useOptimizelyContext } from "../contexts/OptimizelyContext";

type OptimizelyDecisionEmpty = {
  variationKey: string | null;
  // The boolean value indicating if the flag is enabled or not
  enabled: boolean;
  // The collection of variables associated with the decision
  variables: { [variableKey: string]: unknown };
  // The rule key of the decision
  ruleKey: string | null;
  // The flag key for which the decision has been made for
  flagKey: string;
  // A copy of the user context for which the decision has been made for
  userContext: OptimizelyUserContext | null;
  // An array of error/info messages describing why the decision has been made.
  reasons: string[];
};

const createEmptyDecsion = (flagKey: string) => ({
  variationKey: null,
  enabled: false,
  ruleKey: null,
  flagKey,
  variables: {},
  reasons: [],
  userContext: null,
});

/**
 * A React Hook that retrieves the flag decision, optionally
 * auto updating those values based on underlying user or datafile changes.
 *
 * Note: The react client can become ready AFTER the timeout period.
 *       ClientReady and DidTimeout provide signals to handle this scenario.
 */
export const useOptimizelyDecision = (
  flagKey: string,
  options: OptimizelyDecideOption[] = []
): {
  error: Error | null;
  decision: OptimizelyDecision | OptimizelyDecisionEmpty;
} => {
  const { optimizelyContext } = useOptimizelyContext();
  const { optimizelyClient, optimizelyUserContext } = optimizelyContext;

  if (!optimizelyClient) {
    return {
      error: new Error(
        `Unable to use decision ${flagKey}. Optimizely client not set up`
      ),
      decision: createEmptyDecsion(flagKey),
    };
  }

  if (!optimizelyUserContext) {
    return {
      error: new Error(
        `Unable to use decision ${flagKey}. Optimizely user context not set up`
      ),
      decision: createEmptyDecsion(flagKey),
    };
  }

  const decision: OptimizelyDecision = optimizelyUserContext.decide(
    flagKey,
    options
  );

  if (!decision) {
    return {
      error: new Error(`Unable to use decision ${flagKey}. Decision failed`),
      decision: createEmptyDecsion(flagKey),
    };
  }
  return {
    error: null,
    decision,
  };
};

export default useOptimizelyDecision;
