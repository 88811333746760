import { Box, Checkbox, LegendWrapper } from "@cruk/cruk-react-components";
import { useEffect, useState } from "react";
import { Material } from "@cruk/activity-management-schema";
import { RegistrationType } from "../../contexts/RegistrationContext";
import { FormFieldProps } from "../../types";
import { getSupporterMaterials } from "../../utils/api/client";

const ContactCentreSportsProductsLookup = ({
  values,
  setValues,
  field,
}: FormFieldProps) => {
  const [supporterMaterials, setSupporterMaterials] = useState<Material[]>([]);

  useEffect(() => {
    const getSupporterMaterialsForPackCodes = async () => {
      const packCodesResult: Material[] = await getSupporterMaterials();
      setSupporterMaterials(packCodesResult);
    };

    getSupporterMaterialsForPackCodes();
  }, []);
  return (
    (values.tshirtOrVest === "neither" ||
      (values.tshirtOrVest !== "neither" && values.tshirtVestSize)) && (
      <Box marginBottom="s" key={field}>
        <LegendWrapper
          legendText="Does the supporter need any additional materials?"
          hintText="Tick all that apply"
        >
          {supporterMaterials.length > 0 &&
            supporterMaterials.map((packCode) => (
              <Checkbox
                key={packCode.code}
                name="contactCentreSportsProductLookUp"
                checked={values.contactCentreSportsProductLookUp.includes(
                  packCode.code
                )}
                disabled={!packCode.enabled}
                onChange={(e) => {
                  if (e.target.checked) {
                    values.contactCentreSportsProductLookUp.push(packCode.code);
                    setValues({
                      ...(values as RegistrationType),
                      contactCentreSportsProductLookUp:
                        values.contactCentreSportsProductLookUp,
                    });
                  } else {
                    const findIndex =
                      values?.contactCentreSportsProductLookUp?.findIndex(
                        (codeForBackend: string) =>
                          codeForBackend === packCode.code
                      );
                    if (findIndex > -1) {
                      values?.contactCentreSportsProductLookUp.splice(
                        findIndex,
                        1
                      );
                      setValues({
                        ...(values as RegistrationType),
                        contactCentreSportsProductLookUp:
                          values.contactCentreSportsProductLookUp,
                      });
                    }
                  }
                }}
              >
                {packCode.name} Pack Code: {packCode.code}
              </Checkbox>
            ))}
        </LegendWrapper>
      </Box>
    )
  );
};

export default ContactCentreSportsProductsLookup;
