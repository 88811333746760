import { Box, Collapse, Text } from "@cruk/cruk-react-components";
import { FormFieldProps } from "../types";
import renderCustomText from "../utils/renderCustomText";

const FormCollapseLabelWhyDoWeNeedThis = ({
  formJourneyPage: { collapseLabel, collapseContent, secondaryHeading },
}: FormFieldProps) => {
  return collapseLabel && collapseContent ? (
    <Box marginBottom={secondaryHeading ? "none" : "s"}>
      <Collapse headerTitleText={collapseLabel} id="why-do-we-need-this">
        <Text>{renderCustomText(collapseContent)}</Text>
      </Collapse>
    </Box>
  ) : null;
};

export default FormCollapseLabelWhyDoWeNeedThis;
