// create timestamp and text label for start of this month + 12 more
const dateOptions = Array.from(Array(13).keys()).map((month, index) => {
  const now = new Date();
  const then = new Date(
    Date.UTC(
      now.getFullYear(),
      now.getMonth() + month,
      index === 0 ? now.getDate() : 1,
      12, // this makes daylight savings time go away
      0
    )
  );

  return {
    value: `${then.toISOString().split(".")[0]}Z`,
    label: then.toLocaleString("en-GB", { month: "long", year: "numeric" }),
    key: month,
  };
});

export default dateOptions;
