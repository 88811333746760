import { Box, Collapse, Select } from "@cruk/cruk-react-components";
import { ChangeEvent } from "react";
import SizeGuide from "../../components/SizeGuide";
import { T_SHIRT } from "../../constants/fields";
import { Field } from "../../constants/journeys";
import { RegistrationType } from "../../contexts/RegistrationContext";
import { FormFieldProps } from "../../types";

const TShirtSize = ({
  values,
  activity: { merchandiseTemplateType, tShirtHeader, products, theme },
  field,
  isRequired,
  journey,
  getFieldProps,
  setValues,
  handleChange,
  formJourneyPage: { fields },
  touched,
  navigate,
  errors,
  optInMerchandise,
}: FormFieldProps) =>
  (values.optInMerchandise === "yes" && merchandiseTemplateType === T_SHIRT) ||
  journey !== "sf-challenges" ? (
    <>
      <Box marginBottom="s" key={field}>
        <Select
          label={tShirtHeader || "T-shirt size"}
          {...getFieldProps("tShirtSize")}
          required={
            (optInMerchandise && merchandiseTemplateType === T_SHIRT) ||
            journey !== "sf-challenges"
              ? isRequired("tShirtSize")
              : false
          }
          onChange={(event: ChangeEvent<HTMLSelectElement>) => {
            setValues({
              ...values,
            } as RegistrationType);
            handleChange(event);
          }}
          errorMessage={touched.tShirtSize ? errors.tShirtSize : undefined}
        >
          <option disabled value="">
            Select size
          </option>
          {journey === "challenge" && (
            <option value="unused">
              I don&apos;t require a t-shirt thanks
            </option>
          )}
          {products
            .filter((product) => product.type === "tshirt")
            .sort((a, b) => a.precedence - b.precedence)
            .map((product) => (
              <option
                value={product.productCode}
                disabled={product.enabled === "N"}
                key={product.productCode}
              >
                {product.metaData.find((md) => md.key === "size-label")
                  ?.value || navigate("/error")}
                {product.enabled === "N" ? " Out of stock" : ""}
              </option>
            ))}
        </Select>
      </Box>
      {/* Size Guide automatically added if we have a tShirtSize field */}
      {(values.optInMerchandise === "yes" &&
        merchandiseTemplateType === T_SHIRT) ||
      (journey !== "sf-challenges" &&
        fields &&
        fields.includes(Field.T_SHIRT_SIZE)) ? (
        <Box marginBottom="s">
          <Collapse headerTitleText="Size guide" id="size-guide">
            <SizeGuide values={values} journey={journey} theme={theme} />
          </Collapse>
        </Box>
      ) : null}
    </>
  ) : null;

export default TShirtSize;
