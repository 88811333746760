/* eslint-disable eslint-comments/disable-enable-pair */

/* eslint-disable no-bitwise */
import { FC } from "react";
import { useFormikContext } from "formik";

function notEmpty<T>(value: T | null): value is T {
  return value !== null;
}

function comparator(a: Element, b: Element) {
  if (a === b) {
    return 0;
  }

  // returns a bitmask describing relationship between these two elements
  const position = a.compareDocumentPosition(b);

  if (
    position & Node.DOCUMENT_POSITION_FOLLOWING ||
    position & Node.DOCUMENT_POSITION_CONTAINED_BY
  ) {
    // a is before b
    return -1;
  }
  if (
    position & Node.DOCUMENT_POSITION_PRECEDING ||
    position & Node.DOCUMENT_POSITION_CONTAINS
  ) {
    // a is after b
    return 1;
  }
  return 0;
}

const ScrollToError: FC = () => {
  const { errors, isSubmitting } = useFormikContext();

  if (isSubmitting && errors) {
    // find the input elements by name with errors from formik
    const elements = Object.keys(errors)
      .map((errorKey) =>
        document.querySelector(`#page-form [name='${errorKey}']`)
      )
      .filter(notEmpty);

    // sort the elements in document order and take the first one
    const [first] = elements.sort(comparator);

    if (first instanceof HTMLElement) {
      first.focus();
    }
  }

  return null;
};

export default ScrollToError;
