import { Box, Button } from "@cruk/cruk-react-components";
import { FormFieldProps } from "../../types";

const CallToAction = ({ activity, field, getClickableLink }: FormFieldProps) =>
  activity.callToActionLabel && activity.callToActionLink ? (
    <Box marginBottom="s" key={field}>
      <Button
        appearance="primary"
        type="button"
        size="m"
        href={getClickableLink(activity.callToActionLink)}
      >
        {activity.callToActionLabel}
      </Button>
    </Box>
  ) : null;

export default CallToAction;
