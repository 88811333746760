import { Box, Heading, IconFa, Link, Text } from "@cruk/cruk-react-components";
import { faSquarePhone } from "@fortawesome/free-solid-svg-icons";
import StyledInfoBox from "../../components/StyledInfoSupportBox";

const SupportCallBox = () => (
  <Box margin="none">
    <Heading margin="none" h4>
      Support call
    </Heading>
    <StyledInfoBox
      marginBottom="l"
      marginTop="xs"
      className="giftsInWillsGuideInfoBox"
      icon={<IconFa color="#00b6ed" faIcon={faSquarePhone} size="6.25em" />}
      titleText=""
      descriptionText=""
    >
      <Text textSize="m" margin="none">
        We are willing to help. If you would like to phone us to support you,
        our telephone number is{" "}
        <Link href="tel:0300 1231022">0300 1231022.</Link>
      </Text>
    </StyledInfoBox>
  </Box>
);

export default SupportCallBox;
