import { FC } from "react";
import styled from "styled-components/macro";

const Div = styled.div`
  padding: ${({ theme }) => theme.spacing.xs};

  @media (min-width: ${({ theme }) => theme.breakpoint.mobile}) {
    padding: ${({ theme }) => theme.spacing.xs} 25%;
  }
`;

const FormWrapper: FC = ({ children }) => <Div>{children}</Div>;

export default FormWrapper;
