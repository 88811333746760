import {
  Box,
  Collapse,
  Heading,
  Link,
  RadioConsent,
  Text,
} from "@cruk/cruk-react-components";
import DividerLine from "../../components/DividerLine";
import { FormFieldProps } from "../../types";
import renderCustomText from "../../utils/renderCustomText";

const OptIn = ({
  values,
  field,
  journeyHelper,
  activity,
  journey,
  getFieldProps,
  handleChange,
}: FormFieldProps) => {
  const contactUsLink =
    "https://www.cancerresearchuk.org/about-us/contact-us/contact-preferences";
  const privacyStatementLink =
    "https://www.cancerresearchuk.org/privacy-statement";

  return (
    <div key={field}>
      <Text>
        {journeyHelper.isSportsFormContactCentreJourney(journey)
          ? "Help bring forward the day when all cancers are cured. Hear about our latest breakthroughs, campaigns and how they can support our life saving work."
          : "Help bring forward the day when all cancers are cured. Hear about our latest breakthroughs, campaigns and how you can support our life saving work."}
      </Text>
      <Text>
        {journeyHelper.isSportsFormContactCentreJourney(journey)
          ? `We may contact them by post or phone unless they select
            'no' below.`
          : `We may contact you by post or phone unless you select
            'no' below.`}
      </Text>
      <Text textWeight={700}>
        {journeyHelper.isSportsFormContactCentreJourney(journey)
          ? `How would they like to hear from us?`
          : `How would you like to hear from us?`}
      </Text>
      <Box marginBottom="s" className="RadioConsentLegend">
        <RadioConsent
          attributes={[
            {
              option: "Yes",
              value: "yes",
            },
            {
              option: "No",
              value: "no",
            },
          ]}
          legend="Email"
          {...getFieldProps("optInEmail")}
          aria-label="Click either yes or no to stay in touch via email"
          onChange={handleChange}
          selectedValue={values.optInEmail as string}
        />
        <RadioConsent
          attributes={[
            {
              option: "Yes",
              value: "yes",
            },
            {
              option: "No",
              value: "no",
            },
          ]}
          legend="Text"
          {...getFieldProps("optInText")}
          aria-label="Click either yes or no to stay in touch via text"
          selectedValue={values.optInText as string}
        />
        <RadioConsent
          attributes={[
            {
              option: "Yes",
              value: "yes",
            },
            {
              option: "No",
              value: "no",
            },
          ]}
          legend="Post"
          selectedValue={values.optInPost as string}
          {...getFieldProps("optInPost")}
          aria-label="Click either yes or no to stay in touch via post"
        />
        <RadioConsent
          attributes={[
            {
              option: "Yes",
              value: "yes",
            },
            {
              option: "No",
              value: "no",
            },
          ]}
          legend="Phone"
          {...getFieldProps("optInPhone")}
          selectedValue={values.optInPhone as string}
          aria-label="Click either yes or no to stay in touch via phone"
        />
      </Box>
      <Text textWeight={700}>
        {journeyHelper.isSportsFormContactCentreJourney(journey)
          ? `Change their mind at any time`
          : `Change your mind at any time`}
      </Text>
      <Text>
        Contact us on&nbsp;
        <Link
          aria-label="If you click on this telephone link you will dial the UK number 0300 123 3379"
          rel="nofollow"
          appearance="secondary"
          href="tel:0300 123 3379"
        >
          0300 123 3379
        </Link>
        &nbsp;or&nbsp;
        <Link
          aria-label="If you click the email link you will be taken to the email preferences@cancer.org.uk"
          rel="nofollow"
          href="mailto:preferences@cancer.org.uk"
        >
          preferences@cancer.org.uk
        </Link>
        &nbsp;or via&nbsp;
        <Link
          aria-label="If you click the web link you will be directed to our contact page"
          rel="nofollow"
          href={contactUsLink}
          target="_blank"
        >
          this form.
        </Link>
        &nbsp;
        <Text paddingTop="m">
          {journeyHelper.isSportsFormContactCentreJourney(journey)
            ? `Their details are safe with us. Check out our`
            : `Your details are safe with us. Check out our`}
          &nbsp;
          <Link
            rel="nofollow"
            aria-label="If you click the web link you will be directed to our privacy policy page"
            href={privacyStatementLink}
            target="_blank"
          >
            Privacy Policy
          </Link>{" "}
          for more details.
        </Text>
        <Text>
          {journeyHelper.isSportsFormContactCentreJourney(journey)
            ? `To make our fundraising efforts effective, we may
        research them using publicly available sources.`
            : `To make our fundraising efforts effective, we may
        research you using publicly available sources.`}
        </Text>
      </Text>
      <Collapse
        headerTitleText={
          journeyHelper.isSportsFormContactCentreJourney(journey)
            ? "Find out how we use their information"
            : "Find out how we use your information"
        }
        id="privacy-explanation-collapse"
      >
        <Text textAlign="justify">
          Examples of publicly available sources we may use include public
          registers, corporate websites, the news or social media.
        </Text>
        <Text>
          {" "}
          This information is stored securely and protected from unauthorised
          access or disclosure. Find out more in our&nbsp;
          <Link
            rel="nofollow"
            aria-label="If you click the web link you will be directed to our privacy policy page"
            href={privacyStatementLink}
            target="_blank"
          >
            privacy policy.
          </Link>
          &nbsp;
          {journeyHelper.isSportsFormContactCentreJourney(journey)
            ? "If they don’t want us to do this research in relation to them, they can tell us anytime by contacting"
            : "If you don’t want us to do this research in relation to you, you can tell us anytime by contacting"}
          &nbsp;
          <Link
            rel="nofollow"
            aria-label="If you click on this telephone link you will dial the UK number 0300 123 3379"
            appearance="secondary"
            href="tel:0300 123 3379"
          >
            0300 123 3379
          </Link>{" "}
          or&nbsp;
          <Link
            rel="nofollow"
            aria-label="If you click the email link you will be taken to the email preferences@cancer.org.uk"
            href="mailto:preferences@cancer.org.uk"
          >
            preferences@cancer.org.uk
          </Link>
        </Text>
        <Text paddingBottom="m" textAlign="justify">
          {journeyHelper.isSportsFormContactCentreJourney(journey)
            ? "We may try to obtain up-to-date contact details for them using external sources if they move house, so that we can stay in touch. "
            : "We may try to obtain up-to-date contact details for you using external sources if you move house, so that we can stay in touch. "}
          &nbsp; &nbsp; &nbsp;
        </Text>
      </Collapse>
      <Text textAlign="justify">
        {journeyHelper.isSportsFormContactCentreJourney(journey)
          ? "We may try to obtain up-to-date contact details for them using external sources if they move house, so that we can stay in touch."
          : "We may try to obtain up-to-date contact details for you using external sources if you move house, so that we can stay in touch."}
      </Text>
      <Collapse
        headerTitleText="Find out more and how to opt out here"
        id="privacy-explanation-collapse"
      >
        <Text paddingBottom="s" textAlign="justify">
          {journeyHelper.isSportsFormContactCentreJourney(journey)
            ? "We may try to obtain up-to-date contact details for them using external sources such as 3rd party organisations (if they previously provided consent to share their data), or the post office national change of address database. This means that we can ensure we have an accurate record of our supporters and ensure that we continue to communicate with them in accordance with their wishes."
            : "We may try to obtain up-to-date contact details for you using external sources such as 3rd party organisations (if you previously provided consent to share your data), or the post office national change of address database. This means that we can ensure we have an accurate record of our supporters and ensure that we continue to communicate with you in accordance with your wishes."}
        </Text>
        <Text>
          {" "}
          {journeyHelper.isSportsFormContactCentreJourney(journey)
            ? "If they don’t want us to do this research in relation to them, they can tell us anytime by contacting"
            : "If you don’t want us to do this research in relation to you, you can tell us anytime by contacting"}
          &nbsp;
          <Link
            rel="nofollow"
            aria-label="If you click on this telephone link you will dial the UK number 0300 123 3379"
            appearance="secondary"
            href="tel:0300 123 3379"
          >
            0300 123 3379
          </Link>{" "}
          or&nbsp;
          <Link
            rel="nofollow"
            appearance="secondary"
            aria-label="If you click the email link you will be taken to the email preferences@cancer.org.uk"
            href="mailto:preferences@cancer.org.uk"
          >
            preferences@cancer.org.uk.
          </Link>
        </Text>
      </Collapse>
      <DividerLine />
      <Heading h2>{activity.termsAndConditionsHeader}</Heading>
      <Box data-cy="terms-and-conditions-html" marginBottom="s">
        {renderCustomText(activity.termsAndConditionsTextHtml)}
      </Box>
    </div>
  );
};

export default OptIn;
