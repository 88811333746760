import cleanDeep from "clean-deep";
import { v4 as uuidv4 } from "uuid";
import {
  Activity,
  ActivityInput,
  ActivityQuery,
  EventListResultSuccess,
  EventQuery,
  EventResultSuccess,
  EventsQuery,
  MutateActivityMutation,
  MutateProductMutation,
  Product,
  ProductInput,
  ProductQuery,
} from "../index";

// A collection of utility methods to aid conversion of activity types.

/**
 * Activity and ActivityInput should be kept in sync.
 * The only difference is that a stored activity has
 * __typename, created and updated fields.
 */
export const convertActivityToInput = (activity: Activity): ActivityInput =>
  cleanDeep(activity, {
    cleanKeys: ["__typename", "created", "updated"],
    emptyArrays: false,
    emptyObjects: false,
    emptyStrings: false,
    nullValues: true,
    undefinedValues: false,
  }) as ActivityInput;

/**
 * Converts a GraphQL responses to the Activity type. This cleans up null
 * values that are added by GraphQL typings.
 */
export const convertActivityResponseToActivity = (
  response: ActivityQuery | MutateActivityMutation
): Activity => {
  const { activity } = response;
  // eslint-disable-next-line no-underscore-dangle
  if (!activity || activity.__typename !== "Activity")
    throw Error("Cannot convert non-Activity");
  return cleanDeep(activity, {
    emptyArrays: false,
    emptyObjects: false,
    emptyStrings: false,
    nullValues: true,
    undefinedValues: false,
  }) as Activity;
};

/**
 * Product and ProductInput should be kept in sync.
 * The only difference is that a stored Product has
 * __typename, created and updated fields.
 */
export const convertProductToInput = (product: Product): ProductInput =>
  cleanDeep(product, {
    cleanKeys: ["__typename", "created", "updated"],
    emptyArrays: false,
    emptyObjects: false,
    emptyStrings: false,
    nullValues: true,
    undefinedValues: false,
  }) as ProductInput;

/**
 * Converts a GraphQL responses to the Product type. This cleans up null
 * values that are added by GraphQL typings.
 */
export const convertProductResponseToProduct = (
  response: ProductQuery | MutateProductMutation
): Product => {
  const { product } = response;
  // eslint-disable-next-line no-underscore-dangle
  if (!product || product.__typename !== "Product")
    throw Error("Cannot convert non-product");
  return cleanDeep(product, {
    emptyArrays: false,
    emptyObjects: false,
    emptyStrings: false,
    nullValues: true,
    undefinedValues: false,
  }) as Product;
};

/**
 * Converts a GraphQL responses to the Events type. This cleans up null
 * values that are added by GraphQL typings.
 */
export const convertEventsResponseToEvents = (
  response: EventsQuery
): EventListResultSuccess => {
  const { events } = response;

  return cleanDeep(events, {
    emptyArrays: false,
    emptyObjects: false,
    emptyStrings: false,
    nullValues: true,
    undefinedValues: false,
  }) as EventListResultSuccess;
};

/**
 * Converts a GraphQL responses to the Event type. This cleans up null
 * values that are added by GraphQL typings.
 */
export const convertEventResponseToEvent = (
  response: EventQuery
): EventResultSuccess => {
  const { event } = response;

  return cleanDeep(event, {
    emptyArrays: false,
    emptyObjects: false,
    emptyStrings: false,
    nullValues: true,
    undefinedValues: false,
  }) as EventResultSuccess;
};

/**
 * Converts the ActivityInput type into a valid Activity for storage.
 * This requires adding the appropriate __typenames and ensuring, created, updated
 * and id fields are correct.
 */
export function convertInputToActivity(
  input: ActivityInput,
  current?: Activity
): Activity {
  const date = new Date().toISOString();
  return {
    __typename: "Activity",
    ...input,
    journeyConfig: {
      __typename: "JourneyConfig",
      ...input.journeyConfig,
      fields: input.journeyConfig.fields.map((field) => ({
        __typename: "Field",
        ...field,
        validation: { __typename: "FieldValidation", ...field.validation },
      })),
    },
    products: input.products.map((product) => ({
      __typename: "Product",
      ...product,
      metadata: product.metadata.map((metadata) => ({
        __typename: "Metadata",
        ...metadata,
      })),
    })),
    reference: {
      __typename: "Reference",
      ...input.reference,
    },
    id: input.id ?? uuidv4(),
    updated: date,
    created: current ? current.created : date,
  };
}

export function convertInputToProduct(
  input: ProductInput,
  current?: Product
): Product {
  const date = new Date().toISOString();
  return {
    __typename: "Product",
    ...input,
    metadata: input.metadata.map((metadata) => ({
      __typename: "Metadata",
      ...metadata,
    })),
    id: input.id ?? uuidv4(),
    updated: date,
    created: current ? current.created : date,
  };
}
