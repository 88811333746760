import { Box, LegendWrapper, Radio, Text } from "@cruk/cruk-react-components";
import { FormFieldProps } from "../../types";

const DeliveryMethod = ({
  field,
  journey,
  errors,
  getFieldProps,
  values,
  touched,
}: FormFieldProps) => (
  <Box marginBottom="s" key={field}>
    {journey === "general-fundraising-pack-option" && (
      <Text textWeight={700}>Your pack</Text>
    )}
    <LegendWrapper
      legendText="How would you like us to send your pack?"
      errorMessage={touched.deliveryMethod ? errors.deliveryMethod : undefined}
    >
      <Radio
        {...getFieldProps("deliveryMethod")}
        value="email"
        aria-label="Click if you would like your pack to be sent by email"
        checked={values.deliveryMethod === "email"}
      >
        Email with PDF
      </Radio>
      <Radio
        {...getFieldProps("deliveryMethod")}
        value="post"
        aria-label="Click if you would like your pack to be sent by post"
        checked={values.deliveryMethod === "post"}
      >
        Post
      </Radio>
    </LegendWrapper>
  </Box>
);

export default DeliveryMethod;
