import ImageBox from "../../components/ImageBox";
import renderGIGWThankYouImage from "../../assets/GIGW-Thank-You-Page-Image.jpg";
import { FormFieldProps } from "../../types";

const RenderGIGWThankYouImage = ({ field }: FormFieldProps) => (
  <ImageBox
    key={field}
    alt="Displays supporters during an activity on the thank you page"
    src={renderGIGWThankYouImage}
  />
);

export default RenderGIGWThankYouImage;
