import { Box, TextField } from "@cruk/cruk-react-components";
import { FormFieldProps } from "../../types";

const FirstName = ({
  field,
  touched,
  errors,
  activity,
  handleBlur,
  getFieldProps,
  isRequired,
}: FormFieldProps) => (
  <Box marginBottom="s" key={field}>
    <TextField
      label={activity.firstNameLabel || "First name"}
      aria-label="This is a text field to type your first name please enter a valid name with a minimum of two characters to proceed"
      {...getFieldProps("firstName")}
      required={isRequired("firstName")}
      errorMessage={touched.firstName ? errors.firstName : undefined}
      onBlur={handleBlur}
    />
  </Box>
);

export default FirstName;
