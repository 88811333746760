import { Box, Button } from "@cruk/cruk-react-components";
import { FormFieldProps } from "../../types";

const SU2C60CTA = ({ field }: FormFieldProps) => (
  <Box marginBottom="s" key={field}>
    <Button
      appearance="primary"
      size="l"
      href="https://www.facebook.com/fund/standuptocancerUK"
    >
      Set up your Facebook fundraiser
    </Button>
  </Box>
);

export default SU2C60CTA;
