import { Box, LegendWrapper, Radio, Select } from "@cruk/cruk-react-components";
import { ChangeEvent } from "react";
import restrictionCodes from "../../constants/restrictionCodes.json";
import { RegistrationType } from "../../contexts/RegistrationContext";
import { FormFieldProps } from "../../types";
import JourneyHelper from "../../utils/journeyHelper";

const { isDIYJourney } = new JourneyHelper();

const Restriction = ({
  field,
  touched,
  getFieldProps,
  values,
  errors,
  setValues,
  handleChange,
  journey,
}: FormFieldProps) => (
  <Box marginBottom="s" key={field}>
    <LegendWrapper
      legendText={
        isDIYJourney(journey)
          ? "I want to help research into"
          : "Choose where your money goes"
      }
      required={isDIYJourney(journey)}
      hintText="You can raise money for a specific cancer type"
      aria-label="Select either Yes or No from the radio buttons to select where you money goes"
      errorMessage={touched.restriction ? errors.restriction : undefined}
    >
      <Radio
        {...getFieldProps("restriction")}
        aria-label="Select No radio if you are happy for your donation to be used where the need is the greatest"
        value="no"
        checked={values.restriction === "no"}
      >
        Where the need is greatest - your donation will help beat all cancers
        sooner
      </Radio>

      <Radio
        {...getFieldProps("restriction")}
        aria-label="Select Yes radio to choose an area of research"
        value="yes"
        checked={values.restriction === "yes"}
      >
        Choose a cancer type or an area of research
      </Radio>
    </LegendWrapper>
    <Box marginTop="s">
      {values.restriction === "yes" && (
        <Select
          label=""
          {...getFieldProps("restrictionCode")}
          value={values.restrictionCode as string}
          aria-label="Choose cancer type or an area of research"
          onChange={(event: ChangeEvent<HTMLSelectElement>) => {
            setValues({ ...values, validated: false } as RegistrationType);
            handleChange(event);
          }}
        >
          <option value="" disabled>
            Please select
          </option>
          {restrictionCodes.map(({ restrictionCode, label }) => (
            <option value={restrictionCode} key={restrictionCode}>
              {label}
            </option>
          ))}
        </Select>
      )}
    </Box>
  </Box>
);

export default Restriction;
