import { TextAreaField } from "@cruk/cruk-react-components";
import styled from "styled-components/macro";

const StyledTextAreaField = styled(TextAreaField)`
  resize: vertical;
  min-height: 5rem;
  max-height: 20rem;
`;

export default StyledTextAreaField;
