import { Box, TextField } from "@cruk/cruk-react-components";
import { FormFieldProps } from "../../types";

const PhoneNumber = ({
  field,
  values,
  errors,
  touched,
  handleBlur,
  formJourneyPage,
  journey,
  journeyHelper,
  isRequired,
  filterHandleChange,
}: FormFieldProps) => (
  <Box marginBottom="s" key={field}>
    <TextField
      label={formJourneyPage.phoneNumberLabel || "Phone number"}
      hintText={
        journeyHelper.isSportsFormExternalJourney(journey)
          ? "You'll get a call from one of our fundraising experts who will support you every step of the way"
          : journeyHelper.isSportsFormContactCentreJourney(journey)
            ? "They'll get a call from one of our fundraising experts who will support them every step of the way"
            : " "
      }
      type="tel"
      name="phoneNumber"
      required={isRequired("phoneNumber")}
      value={values.phoneNumber as string}
      aria-label="This is a text field to enter your phone number. Only valid UK phone numbers with between 11 and 15 numbers with no spaces in-between are permitted"
      onChange={filterHandleChange()}
      onBlur={handleBlur}
      errorMessage={touched.phoneNumber ? errors.phoneNumber : undefined}
    />
  </Box>
);

export default PhoneNumber;
