import {
  Box,
  ErrorText,
  LegendWrapper,
  Radio,
} from "@cruk/cruk-react-components";
import { FormFieldProps } from "../../types";

const SupporterNeedPack = ({
  values,
  errors,
  touched,
  field,
  getFieldProps,
}: FormFieldProps) =>
  ((values.tshirtOrVest as string) === "neither" ||
    (values.tshirtOrVest !== "neither" && values.tshirtVestSize)) && (
    <Box marginBottom="s" key={field}>
      <LegendWrapper
        legendText="Does the supporter need a printed fundraising pack to be sent out?"
        aria-label="Select option if the supporter needs a printed fundraising pack to be sent out"
        required
      >
        <Radio
          {...getFieldProps("supporterNeedPack")}
          aria-label="Select the radio button yes the fundraising pack will have to be fulfilled"
          value="yes"
          checked={values.supporterNeedPack === "yes"}
        >
          Yes
        </Radio>
        <Radio
          {...getFieldProps("supporterNeedPack")}
          aria-label="Select the radio button no the fundraising pack won't be fulfilled"
          value="no"
          checked={values.supporterNeedPack === "no"}
        >
          No
        </Radio>
      </LegendWrapper>
      <ErrorText>
        {touched.supporterNeedPack ? errors.supporterNeedPack : undefined}
      </ErrorText>
    </Box>
  );

export default SupporterNeedPack;
