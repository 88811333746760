import { FormJourney, FormPage, getFormJourneyConfig } from ".";
import mustache from "mustache";
import { ActivityType } from "../../contexts/ActivityContext";

// Uses mustache to fill the page using the activity provided.
function fillPage(formPage: FormPage, activity: ActivityType): FormPage {
  return {
    ...formPage,
    ...{
      heading: mustache.render(formPage.heading, activity),
      introText: formPage.introText
        ? mustache.render(formPage.introText, activity)
        : undefined,
      collapseLabel: formPage.collapseLabel
        ? mustache.render(formPage.collapseLabel, activity)
        : undefined,
      collapseContent: formPage.collapseContent
        ? mustache.render(formPage.collapseContent, activity)
        : undefined,
      submitButtonLabel: formPage.submitButtonLabel
        ? mustache.render(formPage.submitButtonLabel, activity)
        : undefined,
      backLinkLabel: formPage.backLinkLabel
        ? mustache.render(formPage.backLinkLabel, activity)
        : undefined,
      activityDateTimeLabel: formPage.activityDateTimeLabel
        ? mustache.render(formPage.activityDateTimeLabel, activity)
        : undefined,
      emailAddressLabel: formPage.emailAddressLabel
        ? mustache.render(formPage.emailAddressLabel, activity)
        : undefined,
      emailAddressHintText: formPage.emailAddressHintText
        ? mustache.render(formPage.emailAddressHintText, activity)
        : undefined,
      phoneNumberLabel: formPage.phoneNumberLabel
        ? mustache.render(formPage.phoneNumberLabel, activity)
        : undefined,
      addressHeading: formPage.addressHeading
        ? mustache.render(formPage.addressHeading, activity)
        : undefined,
    },
  };
}

/*
 * Augment the form journey with activity data.
 */
export default function getFormJourney(
  journey: string,
  activity: ActivityType
): FormJourney {
  const formJourneyConfig = getFormJourneyConfig(journey);
  if (!formJourneyConfig) {
    throw new Error(`Unable to find journey: ${journey}`);
  }
  Object.keys(formJourneyConfig.pages).forEach((key) => {
    formJourneyConfig.pages[key] = fillPage(
      formJourneyConfig.pages[key],
      activity
    );
  });
  return formJourneyConfig;
}
