import {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useContext,
  useMemo,
  useState,
} from "react";
import {
  Client,
  EventTags,
  OptimizelyUserContext,
  UserAttributes,
} from "@optimizely/optimizely-sdk";

export type OptimizelyContextStateType = {
  optimizelyClient: Client | null;
  isServerSide: boolean;
  timeout: number | undefined;
  optimizelyUserContext: OptimizelyUserContext | null;
  loadingState: string;
};

export type OptimizelyContextProviderType = [
  OptimizelyContextStateType,
  Dispatch<SetStateAction<OptimizelyContextStateType>>,
];

export type TrackEventProps = {
  eventKey: string;
  overrideUserId?: string;
  overrideAttributes?: UserAttributes;
  eventTags?: EventTags;
};

const defaultState: OptimizelyContextStateType = {
  optimizelyClient: null,
  optimizelyUserContext: null,
  isServerSide: false,
  timeout: 0,
  loadingState: "failed",
};

export const OptimizelyContext = createContext<OptimizelyContextProviderType>([
  { ...defaultState },
  () => {},
]);

export const useOptimizelyContext = () => {
  const [optimizelyContext, setOptimizelyContext] =
    useContext(OptimizelyContext);
  const { optimizelyClient, optimizelyUserContext } = optimizelyContext;

  // helper function to track events using optimizely user context information
  const trackEvent = (trackEventProps: TrackEventProps) => {
    if (!optimizelyClient) return; // todo track error
    const currentUser: OptimizelyUserContext | null | undefined =
      trackEventProps.overrideUserId
        ? optimizelyClient?.createUserContext(
            trackEventProps.overrideUserId,
            trackEventProps.overrideAttributes
          )
        : optimizelyUserContext;
    if (!currentUser) return;
    optimizelyClient.track(
      trackEventProps.eventKey,
      currentUser.getUserId(),
      currentUser.getAttributes(),
      trackEventProps.eventTags
    );
  };

  // helper function to set optimizely user context attributes
  const setOptimizelyUserAttributes = (
    UserAttributesObject: UserAttributes
  ) => {
    if (!optimizelyClient || !optimizelyUserContext) return;
    Object.keys(UserAttributesObject).forEach(
      (key: keyof typeof UserAttributesObject) => {
        optimizelyUserContext.setAttribute(
          `${key}`,
          UserAttributesObject[`${key}`]
        );
      }
    );
  };

  return {
    optimizelyContext,
    setOptimizelyContext,
    setOptimizelyUserAttributes,
    trackEvent,
  };
};

type Props = {
  children: ReactNode;
};

export const OptimizelyContextProvider = ({ children }: Props) => {
  const [state, setState] = useState({
    ...defaultState,
  });

  const value = useMemo(
    () => [state, setState],
    [state]
  ) as OptimizelyContextProviderType;

  return (
    <OptimizelyContext.Provider value={value}>
      {children}
    </OptimizelyContext.Provider>
  );
};
