import { useState } from "react";
import { ActivityType } from "../contexts/ActivityContext";
import JourneyHelper from "../utils/journeyHelper";

const journeyHelper = new JourneyHelper();

type DataLayerEvent =
  | {
      event: "dataLayerLoaded";
      brand: string;
      activity: string;
      pagePath: string;
      pageTitle: string;
      activityType: string;
      sourceCode: string;
      productCode: string;
    }
  | {
      event: "am_interaction";
      interactionName: string;
      interactionAction: string;
    }
  | {
      event: "pageView";
      pagePath: string;
      pageTitle: string;
    }
  | {
      event: "pageView";
      pagePath: string;
      pageTitle: "confirmation";
      registrationId: string;
    };

declare global {
  interface Window {
    dataLayer: Array<DataLayerEvent>;
  }
}

if (typeof window.dataLayer === "undefined") {
  window.dataLayer = [];
}

function debug(event: DataLayerEvent) {
  if (process.env.NODE_ENV === "development") {
    console.groupCollapsed(
      `%cdataLayer event: ${event.event}`,
      "font-weight: bold; color: forestgreen;"
    );
    console.debug(event);
    console.groupEnd();
  }
}

function useDataLayer(): {
  pushDataLayerLoaded: (activity: ActivityType, eventCode: string) => void;
  pushInteraction: (interactionName: string, interactionAction: string) => void;
  pushPageView: (interactionName: string, interactionAction?: string) => void;
  pushPageViewConfirmation: (
    activity: ActivityType,
    registrationId: string
  ) => void;
} {
  const [dataLayerLoadedPushed, setDataLayerLoadedPushed] = useState(false);

  const push = (event: DataLayerEvent) => {
    window.dataLayer.push(event);
    debug(event);
  };

  return {
    pushDataLayerLoaded(activity: ActivityType, eventCode: string = "") {
      if (dataLayerLoadedPushed) {
        return;
      }
      push({
        event: "dataLayerLoaded",
        brand: activity.theme,
        activity: activity.urlName,
        pagePath: window.location.pathname,
        pageTitle: activity.header,
        activityType: activity.activityType,
        sourceCode:
          journeyHelper.isSportsFormExternalJourney(activity.journey) &&
          eventCode
            ? eventCode
            : activity.sourceCode,
        productCode: activity.productCode,
      });
      setDataLayerLoadedPushed(true);
    },
    pushInteraction(interactionName: string, interactionAction: string) {
      push({
        event: "am_interaction",
        interactionName,
        interactionAction,
      });
    },
    pushPageView(title: string) {
      push({
        event: "pageView",
        pagePath: window.location.pathname,
        pageTitle: title,
      });
    },
    pushPageViewConfirmation(activity: ActivityType, registrationId: string) {
      push({
        event: "pageView",
        // this is a "virtual" confirmation page page only used in tracking, this page does not exist in the app routes
        pagePath: `/${activity.urlName}/confirmation`,
        pageTitle: "confirmation",
        registrationId,
      });
    },
  };
}

export default useDataLayer;
