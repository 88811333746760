import { ChangeEvent, ChangeEventHandler } from "react";
import { RegistrationType } from "../contexts/RegistrationContext";

function allowDigits(value: string) {
  return value.replace(/[^0-9]/g, "");
}

function allowDigitsAndPlus(value: string) {
  return value.replace(/[^0-9+]/g, "");
}

function filteredHandleChange(
  handleChange: ChangeEventHandler<HTMLInputElement>,
  values: RegistrationType,
  setValues: (values: RegistrationType) => void
): ChangeEventHandler<HTMLInputElement> {
  return (event: ChangeEvent<HTMLInputElement>) => {
    handleChange(event);

    const {
      currentTarget: { name, value },
    } = event;

    if (name === "dateOfBirthDay") {
      setValues({ ...values, dateOfBirthDay: allowDigits(value) });
    } else if (name === "dateOfBirthMonth") {
      setValues({ ...values, dateOfBirthMonth: allowDigits(value) });
    } else if (name === "dateOfBirthYear") {
      setValues({ ...values, dateOfBirthYear: allowDigits(value) });
    } else if (name === "phoneNumber") {
      setValues({ ...values, phoneNumber: allowDigitsAndPlus(value) });
    } else if (name === "fundraisingTarget") {
      setValues({ ...values, fundraisingTarget: allowDigits(value) });
    } else if (name === "productQuantity") {
      setValues({ ...values, productQuantity: allowDigits(value) });
    }
  };
}

export default filteredHandleChange;
