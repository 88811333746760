import { Box, Button } from "@cruk/cruk-react-components";
import { FormFieldProps } from "../../types";

const SecondCallToAction = ({
  activity,
  getClickableLink,
  field,
}: FormFieldProps) =>
  activity.secondCallToActionLabel && activity.secondCallToActionLink ? (
    <Box marginBottom="s" key={field}>
      <Button
        appearance="secondary"
        type="button"
        size="m"
        href={getClickableLink(activity.secondCallToActionLink)}
      >
        {activity.secondCallToActionLabel}
      </Button>
    </Box>
  ) : null;

export default SecondCallToAction;
