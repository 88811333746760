import { useEffect, useState } from "react";

const isBrowser = typeof window !== "undefined";

const getStorageValue = (key: string, defaultValue: string): string | null => {
  // getting stored value
  if (!isBrowser) return defaultValue;
  const saved: string | null = localStorage.getItem(key);
  const initial = saved ? (JSON.parse(saved) as string) : null;
  return initial || defaultValue;
};

export const useLocalStorage = (key: string, defaultValue: string) => {
  const [value, setValue] = useState(() => getStorageValue(key, defaultValue));

  useEffect(() => {
    // storing input name
    localStorage.setItem(key, JSON.stringify(value));
  }, [key, value]);

  return { value, setValue };
};

export default useLocalStorage;
