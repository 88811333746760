import { FieldInput } from "@cruk/activity-management-schema";

/**
 * Class to encapsulate the editing of FieldInput values.
 * This simplifies the mutations by using a map instead of an
 * array when editing.
 */
export class FieldUtils {
  private fieldMap: {
    [key: string]: FieldInput;
  };

  constructor(fields?: FieldInput[]) {
    this.fieldMap = (fields ?? []).reduce(
      (map, entry) => Object.assign(map, { [entry.field]: entry }),
      {} as { [key: string]: FieldInput }
    );
  }

  get(fieldName: string): FieldInput | undefined {
    return fieldName in this.fieldMap ? this.fieldMap[fieldName] : undefined;
  }

  set(input: FieldInput): void {
    this.fieldMap[input.field] = input;
  }

  // The same as set except it keeps existing fields if not overwritten.
  edit(field: string, input: Partial<FieldInput>): void {
    this.set({ field, ...this.get(field), ...input });
  }

  delete(fieldName: string): void {
    if (fieldName in this.fieldMap) {
      delete this.fieldMap[fieldName];
    }
  }

  toArray(): FieldInput[] {
    return Object.values(this.fieldMap);
  }
}

export default FieldUtils;
