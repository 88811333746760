import { Box, Button } from "@cruk/cruk-react-components";
import { BackAction } from "../constants/journeys";
import { RegistrationType } from "../contexts/RegistrationContext";
import { FormFieldProps } from "../types";
import StepsButtonWrapper from "./StepButtonsWrapper";
import SubmitButton from "./SubmitButton";

const FormSubmitNextAndBackButton = ({
  values,
  isSubmitting,
  submitted,
  previousPage,
  formJourneyPage: { backLinkLabel, backLinkAction, submitButtonLabel },
}: FormFieldProps) => {
  function handleBack(values: RegistrationType) {
    if (backLinkAction === BackAction.BACK) {
      previousPage(values);
    }
  }
  return backLinkAction === BackAction.BACK || submitButtonLabel ? (
    <StepsButtonWrapper>
      {submitButtonLabel && (
        <Box marginLeft={backLinkLabel ? "s" : "none"} className="nextBox">
          <SubmitButton
            className="nextButton"
            aria-label="When you click this submit button your form will be submitted to a new page if all the data you have filled in is complete and correct"
            disabled={isSubmitting || submitted}
          >
            {isSubmitting ? "Submitting..." : submitButtonLabel}
          </SubmitButton>
        </Box>
      )}

      {backLinkAction === BackAction.BACK && (
        <Button
          type="button"
          className="backButton"
          appearance="secondary"
          onClick={() => handleBack(values as RegistrationType)}
        >
          {backLinkLabel}
        </Button>
      )}
    </StepsButtonWrapper>
  ) : null;
};

export default FormSubmitNextAndBackButton;
