import { createContext } from "react";

export type ActivityType = {
  activityMonthDateHeader: string;
  activityName: string;
  activityType: string;
  activityStatus: string;
  activitySuccessHeader: string;
  activitySuccessMessage: string;
  activityDateHeader: string;
  activityDateHelpText: string;
  callToActionLabel?: string;
  callToActionLink?: string;
  secondCallToActionLabel?: string;
  secondCallToActionLink?: string;
  confirmationPageCTALabel?: string;
  secondConfirmationPageCTALabel?: string;
  dobHelpText: string;
  firstNameLabel: string;
  fundraisingPageHeading: string;
  fundraisingTargetLabel: string;
  fundraisingTargetHelpTextHtml: string;
  header: string;
  bannerText: string;
  introTextHtml: string;
  id: string;
  journey: string;
  loaded: boolean;
  lastNameLabel: string;
  moreInfoHeader: string;
  moreInfoHelpText: string;
  merchandiseTemplateType?: string | undefined;
  merchandiseLabelHeader?: string;
  ageRestriction?: string;
  selectedAgeRestriction?: string;
  organisationName: string;
  productQuantityHeader: string;
  productQuantityHelpText: string;
  productQuantityMax: string;
  productQuantityMin: string;
  productQuantityMaxError: string;
  productQuantityMinError: string;
  productCode: string;
  products: Array<{
    productCode: string;
    enabled: "Y" | "N";
    type: "pack" | "tshirt" | "vest";
    metaData: { key: string; value: string }[];
    precedence: number;
  }>;
  sourceCode: string;
  submitButtonLabel: string;
  tShirtHeader?: string;
  termsAndConditionsHeader: string;
  termsAndConditionsTextHtml: string;
  typeConfig: Record<string, string>;
  theme: "cruk" | "rfl" | "su2c";
  urlName: string;
  metaTitle?: string;
  metaDescription?: string;
};

export const emptyActivity: ActivityType = {
  activityMonthDateHeader: "",
  activityDateHeader: "",
  activityDateHelpText: "",
  activityName: "",
  activityType: "",
  activitySuccessHeader: "",
  activitySuccessMessage: "",
  callToActionLabel: "",
  callToActionLink: "",
  secondCallToActionLabel: "",
  secondCallToActionLink: "",
  confirmationPageCTALabel: "",
  secondConfirmationPageCTALabel: "",
  dobHelpText: "",
  fundraisingPageHeading: "",
  fundraisingTargetLabel: "",
  firstNameLabel: "",
  fundraisingTargetHelpTextHtml: "",
  header: "",
  id: "",
  bannerText: "",
  introTextHtml: "",
  journey: "",
  loaded: false,
  lastNameLabel: "",
  moreInfoHeader: "",
  moreInfoHelpText: "",
  merchandiseTemplateType: "",
  merchandiseLabelHeader: "",
  ageRestriction: "",
  selectedAgeRestriction: "",
  organisationName: "",
  products: [],
  productCode: "",
  productQuantityHeader: "",
  productQuantityHelpText: "",
  productQuantityMax: "",
  productQuantityMin: "",
  productQuantityMaxError: "",
  productQuantityMinError: "",
  sourceCode: "",
  submitButtonLabel: "",
  theme: "cruk",
  tShirtHeader: "",
  typeConfig: {},
  termsAndConditionsHeader: "",
  termsAndConditionsTextHtml: "",
  urlName: "",
  activityStatus: "",
};

const ActivityContext = createContext(emptyActivity);

export default ActivityContext;
