import { useEffect } from "react";
import optimizely, { createInstance } from "@optimizely/optimizely-sdk";
import { v4 as uuidv4 } from "uuid";
import { useOptimizelyContext } from "../contexts/OptimizelyContext";
import { useLocalStorage } from "../hooks/useLocalStorage";

export type OptimizelyLoaderProps = {
  sdkKey: string;
  onError?: (err: Error) => void;
};

const ENV_NAME = process.env.REACT_APP_STAGE || "development";

/**
 * OptimizelyLoader initialises optimizely and sets user details
 */
export const OptimizelyLoader = ({
  sdkKey = "",
  onError,
}: OptimizelyLoaderProps) => {
  const { optimizelyContext, setOptimizelyContext } = useOptimizelyContext();
  // "user_id_not_set" is default effectively user_id unset
  const { value: optimizelyId, setValue: setOptimizelyId } = useLocalStorage(
    "optimizely",
    "user_id_not_set"
  );

  if (ENV_NAME === "PRODUCTION") {
    optimizely.setLogger(null);
  }

  // init optimizely if we have a sdk key
  useEffect(() => {
    if (!sdkKey.length || optimizelyContext.optimizelyClient) return;
    const client = createInstance({
      sdkKey,
      datafileOptions: { autoUpdate: true, updateInterval: 300000 },
      eventBatchSize: 20,
      eventFlushInterval: 1000,
    });
    if (client) {
      client
        .onReady()
        .then((success) => {
          setOptimizelyContext({
            ...optimizelyContext,
            optimizelyClient: client,
            loadingState: success ? "ready" : "failed",
          });
          return undefined;
        })
        .catch((err: Error) => {
          if (onError) onError(err);
        });
    }
  }, [sdkKey]);

  // wait till we have access to browser to see if there is a stored optimizely id
  // if so use it, if not create one and use that
  useEffect(() => {
    if (optimizelyId === "user_id_not_set") {
      const randomId: string = uuidv4();
      setOptimizelyId(randomId);
    }
  }, [optimizelyId]);

  // once we get/set an optimizely id and we have an optimizely client
  // create a user context if one doesn't exist
  useEffect(() => {
    if (
      !sdkKey.length ||
      !optimizelyContext.optimizelyClient ||
      !optimizelyId ||
      optimizelyId === "user_id_not_set" ||
      optimizelyContext.optimizelyUserContext
    )
      return;

    const userContext = optimizelyContext.optimizelyClient.createUserContext(
      optimizelyId,
      { user_id: optimizelyId }
    );
    setOptimizelyContext({
      ...optimizelyContext,
      optimizelyUserContext: userContext,
    });
  }, [optimizelyContext.optimizelyClient, optimizelyId]);

  return null;
};

export default OptimizelyLoader;
