import { Box } from "@cruk/cruk-react-components";
import ImageBox from "../../components/ImageBox";
import SpaceAroundRows from "../../components/SpaceAroundRows";
import giftsInWillsGuideImage from "../../assets/gifts-in-wills-guide-form-image.jpg";

const GiftsInWillsGuideImage = () => (
  <Box marginBottom="s">
    <SpaceAroundRows>
      <ImageBox
        alt="Displays gifts and wills guide image"
        src={giftsInWillsGuideImage}
      />
    </SpaceAroundRows>
  </Box>
);

export default GiftsInWillsGuideImage;
