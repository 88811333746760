import { Button, ThemeType } from "@cruk/cruk-react-components";
import { ButtonHTMLAttributes, FC } from "react";
import styled from "styled-components/macro";

type SubmitButtonProps = ButtonHTMLAttributes<HTMLElement> & {
  theme?: ThemeType;
  appearance?: "primary" | "secondary" | "tertiary";
};

const StyledButton = styled(Button)`
  @media (min-width: ${
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-return
      ({ theme }) => theme.breakpoint.mobile
    }) {
    width: auto;
  }
`;

const SubmitButton: FC<SubmitButtonProps> = (props) => (
  <StyledButton {...props} type="submit" size="m" />
);

export default SubmitButton;
