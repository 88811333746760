import { createContext, Dispatch, SetStateAction } from "react";

export type RegistrationType = {
  dateOfBirthDay: string;
  dateOfBirthMonth: string;
  dateOfBirthYear: string;
  firstName: string;
  lastName: string;
  emailAddress: string;
  phoneNumber: string;
  country: string;
  fundraisingTarget: string;
  addressLine1: string;
  addressLine2: string;
  addressLine3: string;
  city: string;
  postcode: string;
  validated: boolean;
  optInEmail: "yes" | "no" | "unknown";
  optInText: "yes" | "no" | "unknown";
  optInPost: "yes" | "no" | "unknown";
  optInPhone: "yes" | "no" | "unknown";
  optInMerchandise: "yes" | "no" | "unknown";
  deliveryMethod: "email" | "post" | undefined;
  activityType: string;
  activityDateTime: string;
  moreInfo: string;
  restriction: "yes" | "no";
  restrictionCode: string;
  tShirtSize: string;
  tshirtVestSize: string;
  maleFemaleStyles: string;
  contactCentreSportsProductLookUp: Array<string>;
  celebrationDateDay: string;
  celebrationDateMonth: string;
  celebrationDateYear: string;
  organisationName: string;
  productQuantity: string;
  ironOnText: string;
  haveEnteredEvent: boolean;
  tshirtOrVest: string;
  eventName?: string | undefined;
  eventCode?: string | undefined | null;
  eventTypes?: Array<string> | undefined;
  eventStart?: string | undefined;
  isGenericEvent?: boolean | undefined;
  hasOnlineFundraisingPage?: "yes" | "no" | undefined;
  supporterNeedPack?: "yes" | "no" | undefined;
  addressActiveOption?: string | undefined;
  addressSearchStatus?: string | undefined;
  informationLookingFor?: string | undefined;
  informationLookingForFreeText?: string | undefined;
};

const initialValue: {
  registration: RegistrationType;
  setRegistration: Dispatch<SetStateAction<RegistrationType>>;
} = {
  registration: {
    dateOfBirthDay: "",
    dateOfBirthMonth: "",
    dateOfBirthYear: "",
    firstName: "",
    lastName: "",
    emailAddress: "",
    phoneNumber: "",
    country: "",
    fundraisingTarget: "",
    addressLine1: "",
    addressLine2: "",
    addressLine3: "",
    city: "",
    postcode: "",
    validated: false,
    optInEmail: "unknown",
    optInText: "unknown",
    optInPost: "yes",
    optInPhone: "yes",
    optInMerchandise: "unknown",
    deliveryMethod: undefined,
    activityType: "",
    activityDateTime: "",
    moreInfo: "",
    restriction: "no",
    restrictionCode: "",
    tShirtSize: "",
    tshirtVestSize: "",
    maleFemaleStyles: "",
    contactCentreSportsProductLookUp: [],
    celebrationDateDay: "",
    celebrationDateMonth: "",
    celebrationDateYear: "",
    organisationName: "",
    productQuantity: "",
    ironOnText: "",
    haveEnteredEvent: false,
    tshirtOrVest: "",
    eventName: "",
    eventCode: "",
    eventTypes: [],
    eventStart: "",
    isGenericEvent: false,
    hasOnlineFundraisingPage: undefined,
    supporterNeedPack: undefined,
    addressActiveOption: undefined,
    addressSearchStatus: undefined,
  },
  setRegistration() {},
};

const RegistrationContext = createContext(initialValue);

export default RegistrationContext;
