import { FC, useEffect, useState } from "react";
import RegistrationContext, {
  RegistrationType,
} from "../contexts/RegistrationContext";
import transform from "../utils/transform";

const RegistrationContainer: FC = ({ children }) => {
  const [registration, setRegistration] = useState<RegistrationType>({
    dateOfBirthDay: "",
    dateOfBirthMonth: "",
    dateOfBirthYear: "",
    firstName: "",
    lastName: "",
    emailAddress: "",
    phoneNumber: "",
    country: "GBR",
    fundraisingTarget: "",
    addressLine1: "",
    addressLine2: "",
    addressLine3: "",
    city: "",
    postcode: "",
    validated: false,
    optInEmail: "unknown",
    optInText: "unknown",
    optInPost: "yes",
    optInPhone: "yes",
    optInMerchandise: "unknown",
    deliveryMethod: undefined,
    activityType: "",
    activityDateTime: "",
    moreInfo: "",
    restriction: "no",
    restrictionCode: "",
    tShirtSize: "",
    tshirtVestSize: "",
    maleFemaleStyles: "",
    contactCentreSportsProductLookUp: [],
    celebrationDateDay: "",
    celebrationDateMonth: "",
    celebrationDateYear: "",
    organisationName: "",
    productQuantity: "",
    ironOnText: "",
    haveEnteredEvent: false,
    tshirtOrVest: "",
    eventName: "",
    eventCode: "",
    eventTypes: [],
    eventStart: "",
    isGenericEvent: false,
    hasOnlineFundraisingPage: undefined,
    supporterNeedPack: undefined,
    informationLookingFor: "",
    informationLookingForFreeText: "",
  });

  function debug() {
    if (process.env.NODE_ENV === "development") {
      console.groupCollapsed(
        `%cRegistration`,
        "font-weight: bold; color: rebeccapurple;"
      );
      console.log(registration);
      console.groupEnd();
      console.groupCollapsed(
        `%cTransform(Registration)`,
        "font-weight: bold; color: rebeccapurple;"
      );
      console.log(transform(registration));
      console.groupEnd();
    }
    if (window.Cypress) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      window.registration = registration;
    }
  }

  useEffect(debug, [registration]);

  return (
    <RegistrationContext.Provider
      value={{
        registration,
        setRegistration,
      }}
      // eslint-disable-next-line react/no-children-prop
      children={children}
    />
  );
};

export default RegistrationContainer;
