import { Box, TextField } from "@cruk/cruk-react-components";
import { FormFieldProps } from "../../types";
import renderCustomText from "../../utils/renderCustomText";

const FundraisingTarget = ({
  journeyHelper,
  journey,
  touched,
  errors,
  handleBlur,
  values,
  filterHandleChange,
  field,
  isRequired,
  activity,
}: FormFieldProps) =>
  (!journeyHelper.isSportsFormJourney(journey) ||
    (journeyHelper.isSportsFormExternalJourney(journey) &&
      values.haveEnteredEvent) ||
    (journeyHelper.isSportsFormContactCentreJourney(journey) &&
      values.hasOnlineFundraisingPage) ||
    (journeyHelper.isSportsFormPromoterJourney(journey) &&
      values.haveEnteredEvent)) && (
    <Box marginBottom="s" key={field}>
      <TextField
        label={activity.fundraisingTargetLabel}
        type="text"
        inputMode="numeric"
        pattern="[0-9]*"
        hintText={
          <>{renderCustomText(activity.fundraisingTargetHelpTextHtml)}</>
        }
        name="fundraisingTarget"
        required={isRequired("fundraisingTarget")}
        value={values.fundraisingTarget}
        onChange={filterHandleChange()}
        onBlur={handleBlur}
        errorMessage={
          !!touched.fundraisingTarget && !!errors.fundraisingTarget
            ? errors.fundraisingTarget
            : ""
        }
        extraLeft="£"
      />
    </Box>
  );

export default FundraisingTarget;
