import { CookieBannerInput } from "@cruk/activity-management-schema";
import { getCookieValue } from "./getCookieValue";

export function cookieBannerInput(): CookieBannerInput {
  const cookieData = getCookieValue("OptanonConsent");

  if (!cookieData) {
    return {
      fun: undefined,
      per: undefined,
      snc: undefined,
      tar: undefined,
    };
  }

  const cookieDataKeyValues = cookieData.split("&");
  const cookieInputString = cookieDataKeyValues
    .find((string) => string.startsWith("groups"))
    ?.split("=")[1]!;

  const cookieInput = Object.fromEntries(
    decodeURIComponent(cookieInputString)
      .trim()
      .split(",")
      .map((keyValue) => {
        const pair = keyValue.split(":");
        return [pair[0], pair[1] === "1"];
      })
  );

  return {
    fun: cookieInput?.fun,
    per: cookieInput?.per,
    snc: cookieInput?.snc,
    tar: cookieInput?.tar,
  };
}
