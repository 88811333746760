import { Box, Text } from "@cruk/cruk-react-components";
import { FormFieldProps } from "../../types";

const DynamicEventName = ({ field, eventRegistered }: FormFieldProps) => (
  <Box marginBottom="s" key={field}>
    <Text>
      {eventRegistered && (
        // eslint-disable-next-line react/jsx-no-comment-textnodes
        <Text textSize="l">
          You are taking part in the &quot;
          {eventRegistered?.title}&quot;
        </Text>
      )}
    </Text>
  </Box>
);

export default DynamicEventName;
