// eslint-disable-next-line eslint-comments/disable-enable-pair

/* eslint-disable @typescript-eslint/no-unsafe-call */
// eslint-disable-next-line eslint-comments/disable-enable-pair

/* eslint-disable @typescript-eslint/no-unsafe-member-access */
// eslint-disable-next-line eslint-comments/disable-enable-pair

/* eslint-disable @typescript-eslint/restrict-template-expressions */
import { RegistrationType } from "../contexts/RegistrationContext";
import isUkCountry from "./isUkCountry";

function transformName(name: string) {
  return name
    .trim()
    .toLowerCase()
    .replace(/\s+/g, " ") // multiple spaces => single space
    .replace(/[‘’]/g, "'") // curly quote => straight quote
    .replace(/\s?(['-])\s?/g, "$1") // space around quote or dash => no space
    .replace(/^mc(.)/, (_, c) => `Mc${c.toUpperCase()}`) // mcmanus => McManus
    .replace(/^mac(.)/, (_, c) => `Mac${c.toUpperCase()}`) // macdonald => MacDonald
    .replace(/^(.)|['.-\s](.)|\s/g, (c) => c.toUpperCase()); // sophie ellis-bextor => Sophie Ellis-Bextor
}

function transformEmailAddress(emailAddress: string) {
  return emailAddress.trim().toLowerCase();
}

function transformAddressLine(addressLine: string) {
  return addressLine
    .trim()
    .toLowerCase()
    .replace(/\s+/g, " ")
    .replace(/[^a-zA-Z0-9 \-/'.]/g, "")
    .replace(/^(.)|['.-\s](.)|\s/g, (c) => c.toUpperCase());
}

function transformCity(city: string) {
  return city.trim().toUpperCase().replace(/\s+/g, " ");
}

function transformPostcodeIntl(postcode: string) {
  return postcode.trim() === ""
    ? "00000"
    : postcode.trim().toUpperCase().replace(/\s+/g, " ");
}

function transformPostcodeUk(postcode: string) {
  const pc = postcode.toUpperCase().replace(/[^a-zA-Z0-9]/g, "");

  return `${pc.substr(0, pc.length - 3)} ${pc.substr(pc.length - 3, 3)}`;
}

function transformCelebrationDate(
  celebrationDateDay: string,
  celebrationDateMonth: string,
  celebrationDateYear: string
) {
  const celebrationDate = new Date(
    Date.UTC(
      parseInt(celebrationDateYear, 10),
      parseInt(celebrationDateMonth, 10) - 1,
      parseInt(celebrationDateDay, 10),
      12, // this makes daylight savings go away
      0
    )
  );

  return `${celebrationDate.toISOString().split(".")[0]}Z`;
}

export default function transform({
  firstName,
  lastName,
  emailAddress,
  country,
  addressLine1,
  addressLine2,
  addressLine3,
  city,
  postcode,
  celebrationDateDay,
  celebrationDateMonth,
  celebrationDateYear,
  activityDateTime,
  ironOnText,
  hasOnlineFundraisingPage,
  tshirtVestSize,
  maleFemaleStyles,
  contactCentreSportsProductLookUp,
  haveEnteredEvent,
  tshirtOrVest,
  ...registration
}: RegistrationType): RegistrationType {
  const newRegistration = {
    ...registration,
    firstName: firstName ? transformName(firstName) : "",
    lastName: lastName ? transformName(lastName) : "",
    emailAddress: emailAddress ? transformEmailAddress(emailAddress) : "",
    country: country || "",
    addressLine1: addressLine1 ? transformAddressLine(addressLine1) : "",
    addressLine2: addressLine2 ? transformAddressLine(addressLine2) : "",
    addressLine3: addressLine3 ? transformAddressLine(addressLine3) : "",
    city: city ? transformCity(city) : "",
    postcode: isUkCountry(country)
      ? postcode
        ? transformPostcodeUk(postcode)
        : ""
      : postcode === ""
        ? transformPostcodeIntl(postcode)
        : postcode
          ? transformPostcodeIntl(postcode)
          : "",
    celebrationDateDay: celebrationDateDay || "",
    celebrationDateMonth: celebrationDateMonth || "",
    celebrationDateYear: celebrationDateYear || "",
    activityDateTime:
      celebrationDateDay && celebrationDateMonth && celebrationDateYear
        ? transformCelebrationDate(
            celebrationDateDay,
            celebrationDateMonth,
            celebrationDateYear
          )
        : activityDateTime,
    ironOnText: ironOnText || "",
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    maleFemaleStyles: maleFemaleStyles || "",
    contactCentreSportsProductLookUp: contactCentreSportsProductLookUp || "",
    haveEnteredEvent,
    tshirtOrVest: tshirtOrVest || "",
    tshirtVestSize: tshirtVestSize || "",
    hasOnlineFundraisingPage: hasOnlineFundraisingPage || undefined,
  };

  if (newRegistration.addressLine1 === newRegistration.addressLine2) {
    newRegistration.addressLine2 = "";
  }

  if (
    newRegistration.addressLine1 === newRegistration.addressLine3 ||
    newRegistration.addressLine2 === newRegistration.addressLine3
  ) {
    newRegistration.addressLine3 = "";
  }

  if (newRegistration.addressLine2 === "") {
    newRegistration.addressLine2 = newRegistration.addressLine3;
    newRegistration.addressLine3 = "";
  }

  return newRegistration;
}
