import { Box, Button } from "@cruk/cruk-react-components";
import { FormFieldProps } from "../../types";

const Run60CTA = ({ field }: FormFieldProps) => (
  <Box marginBottom="s" key={field}>
    <Button
      appearance="primary"
      size="l"
      href="https://www.facebook.com/groups/walkallovercancer2022/"
    >
      Join our Facebook Group
    </Button>
  </Box>
);

export default Run60CTA;
