import {
  Box,
  IconFa,
  InfoBox,
  LegendWrapper,
  Radio,
} from "@cruk/cruk-react-components";
import { faCircleCheck } from "@fortawesome/free-solid-svg-icons";
import { FormFieldProps } from "../../types";

const HasOnlineFundraisingPage = ({
  values,
  errors,
  touched,
  journeyHelper,
  journey,
  field,
  isRequired,
  getFieldProps,
}: FormFieldProps) => {
  return values.haveEnteredEvent ? (
    <Box marginBottom="s" key={field}>
      <LegendWrapper
        errorMessage={
          touched.hasOnlineFundraisingPage
            ? errors.hasOnlineFundraisingPage
            : undefined
        }
        required={isRequired("hasOnlineFundraisingPage")}
        aria-label="Select if the supporter need a Cancer Research UK Giving Page created for this event?"
        legendText="Does the supporter need a Cancer Research UK Giving Page created for this event?"
      >
        <Radio
          {...getFieldProps("hasOnlineFundraisingPage")}
          checked={values.hasOnlineFundraisingPage === "yes"}
          value="yes"
        >
          Yes
        </Radio>
        <Radio
          {...getFieldProps("hasOnlineFundraisingPage")}
          checked={values.hasOnlineFundraisingPage === "no"}
          value="no"
        >
          No
        </Radio>
      </LegendWrapper>
      {values.hasOnlineFundraisingPage &&
        journeyHelper.isSportsFormContactCentreJourney(journey) && (
          <InfoBox
            className="infoBoxHeading"
            marginTop="s"
            marginBottom="s"
            descriptionText={
              values.hasOnlineFundraisingPage === "no"
                ? "They can continue to use the page they've already created to get started with their fundraising"
                : "We'll create a Cancer Research UK Giving Page for them after you've completed this form which you can share with them"
            }
            icon={
              <IconFa
                color="#3D844C"
                faIcon={faCircleCheck}
                size="1.5em"
                aria-label="CheckCircleHasOnlineFundraisingPage"
              />
            }
            titleText={
              values.hasOnlineFundraisingPage === "no"
                ? "Great"
                : "Giving Page creation"
            }
          />
        )}
    </Box>
  ) : null;
};

export default HasOnlineFundraisingPage;
