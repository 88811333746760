import ImageBox from "../../components/ImageBox";
import renderFundraisingPackImage from "../../assets/fundraisingPackImage.jpg";
import { FormFieldProps } from "../../types";

const RenderImage = ({ field, renderImageSrc }: FormFieldProps) => (
  <ImageBox
    key={field}
    alt="Displays a fundraising pack used for fundraising events"
    src={renderImageSrc ?? renderFundraisingPackImage}
  />
);

export default RenderImage;
