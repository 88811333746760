import { useQuery } from "react-query";
import {
  GET_ACTIVITIES,
  GET_ACTIVITY_BY_ID,
  GET_PRODUCT_BY_ID,
  GET_PRODUCTS,
  GetQueryTypes,
  GET_ACTIVITY_TYPES,
  GET_PRODUCT_CODES,
  GET_EVENT,
  GET_EVENTS,
} from "../constants/getQueryTypes";
import {
  getActivities,
  getActivityById,
  getActivityTypes,
  getEvent,
  getEvents,
  getProductById,
  getProductCodes,
  getProducts,
} from "../utils/api/client";
import { ListActivity } from "../Admin/types";
import {
  Activity,
  EventListResultSuccess,
  EventResultSuccess,
  Product,
} from "@cruk/activity-management-schema";

type UseGetQueryResult<T> = {
  data: T | undefined;
  error: unknown;
  isError: boolean;
  isLoading: boolean;
  isSuccess: boolean;
};

const fetchData = async <
  T extends
    | ListActivity[]
    | Activity
    | Product[]
    | Product
    | string[]
    | EventResultSuccess
    | EventListResultSuccess,
>(
  type: GetQueryTypes,
  id?: string
): Promise<T | undefined | []> => {
  try {
    switch (type) {
      case GET_ACTIVITIES:
        return (await getActivities()) as T;
      case GET_ACTIVITY_BY_ID:
        return (await getActivityById(id!)) as T;
      case GET_PRODUCTS:
        return (await getProducts({})) as T;
      case GET_PRODUCT_BY_ID:
        if (!id) return undefined;
        return (await getProductById(id)) as T;
      case GET_ACTIVITY_TYPES:
        return (await getActivityTypes()) as T;
      case GET_PRODUCT_CODES:
        return (await getProductCodes()) as T;
      case GET_EVENT:
        if (!id) return undefined;
        return (await getEvent(id)) as T;
      case GET_EVENTS:
        if (!id) return [];
        return (await getEvents(id)) as T;
      default:
        return undefined;
    }
  } catch (error) {
    throw error;
  }
};

function useGetQuery<T>(
  type: GetQueryTypes,
  id?: string,
  enabled?: boolean
): UseGetQueryResult<T> {
  return useQuery({
    queryKey: [type, id, enabled],
    queryFn: async () => fetchData(type, id),
    // only fetch data when enabled
    enabled: enabled ?? true,
    // When this time elapses, useQuery will refetch the cached result when a component mounts - default = 0ms
    staleTime: 10000,
  });
}

export default useGetQuery;
