import { ConversionInput } from "@cruk/activity-management-schema";
import { ActivityType } from "../contexts/ActivityContext";
import { getCookieValue } from "./getCookieValue";
import moment from "moment";

type DataLayer = {
  event: "am_interaction";
  interactionName: string;
  interactionAction: string;
};

export const conversionInput = (activity: ActivityType): ConversionInput => {
  const eventId = (
    window.dataLayer?.find(
      (key) => (key as DataLayer)?.interactionName === "eventId"
    ) as DataLayer
  )?.interactionAction;

  const clientUserAgent = navigator.userAgent;

  return {
    eventTime: moment().utc().unix(),
    eventId,
    eventSourceUrl: `${window.location.origin}/${activity?.urlName}`,
    clientUserAgent,
    formId: activity?.id,
    activityName: activity?.activityName,
    activityUrl: activity?.urlName,
    brand: activity?.theme,
    fbc: getCookieValue("_fbc"),
    fbp: getCookieValue("_fbp"),
  };
};
