export const GET_ACTIVITIES = "getActivities";
export const GET_ACTIVITY_BY_ID = "getActivityById";
export const GET_PRODUCTS = "getProducts";
export const GET_PRODUCT_BY_ID = "getProductById";
export const GET_ACTIVITY_TYPES = "getActivityTypes";
export const GET_PRODUCT_CODES = "getProductCodes";
export const GET_EVENT = "getEvent";
export const GET_EVENTS = "getEvents";

export type GetQueryTypes =
  | typeof GET_PRODUCTS
  | typeof GET_PRODUCT_BY_ID
  | typeof GET_ACTIVITIES
  | typeof GET_ACTIVITY_BY_ID
  | typeof GET_ACTIVITY_TYPES
  | typeof GET_PRODUCT_CODES
  | typeof GET_EVENT
  | typeof GET_EVENTS;
