import {
  Box,
  IconFa,
  InfoBox,
  LegendWrapper,
  Link,
  Radio,
  Text,
} from "@cruk/cruk-react-components";
import { OTHER_MERCHANDISE, T_SHIRT } from "../../constants/fields";
import { FormFieldProps } from "../../types";

const MerchandiseType = ({
  setOptInMerchandise,
  errors,
  handleChange,
  values: { optInMerchandise },
  activity: { merchandiseLabelHeader, merchandiseTemplateType },
}: FormFieldProps) => {
  const infoBoxData = (): {
    header: string;
    description: string;
    additionalDescription?: string;
    link?: JSX.Element;
  } => {
    switch (merchandiseTemplateType) {
      case T_SHIRT:
        if (optInMerchandise === "yes") {
          return {
            header: "You've selected a t-shirt!",
            description:
              "We hope you're excited to wear your free t-shirt and start your challenge for life-saving research! Kickstart your fundraising today, but please allow for 10 working days for your pack to arrive.",
            additionalDescription:
              "Sending out your pack comes at a cost to Cancer Research UK so please only order one pack if you intend to fundraise to ensure as much fundraising goes towards our life-saving research. Additional t-shirts can be purchased ",
            link: (
              <Link
                target="_blank"
                href="https://shop.cancerresearchuk.org/product/cancer-research-uk-t-shirt?_gl=1*16dz7cs*_gcl_aw*R0NMLjE2OTU3MjYyMDIuQ2p3S0NBandnc3FvQmhCTkVpd0F3ZTV3MDVsZ25ad2ZXTndRZkdOOHZXLTJIYkNkVzVjUnVZMVA1SThsR0VsZHNMSU9WSUhSMEFHeWlCb0MtSkVRQXZEX0J3RQ..*_gcl_dc*R0NMLjE2OTU3MjYyMDIuQ2p3S0NBandnc3FvQmhCTkVpd0F3ZTV3MDVsZ25ad2ZXTndRZkdOOHZXLTJIYkNkVzVjUnVZMVA1SThsR0VsZHNMSU9WSUhSMEFHeWlCb0MtSkVRQXZEX0J3RQ..*_gcl_au*MTA0NzgyMDQ4OC4xNjk1MTE0ODky*_ga*Nzk0NjA5MDI2LjE2ODczMzgyNjE.*_ga_58736Z2GNN*MTY5ODEzNTA0Mi4xMzguMS4xNjk4MTM2NDY1LjAuMC4w&_ga=2.73530578.1757210281.1698054568-794609026.1687338261&_gac=1.124530424.1695726215.CjwKCAjwgsqoBhBNEiwAwe5w05lgnZwfWNwQfGN8vW-2HbCdW5cRuY1P5I8lGEldsLIOVIHR0AGyiBoC-JEQAvD_BwE"
              >
                here.
              </Link>
            ),
          };
        }
        return {
          header: "You've opted out!",
          description:
            "By opting out to receiving a t-shirt, you're saving Cancer Research UK money, which we can now use to fund research. This saving could buy a test tube rack, which make sure our scientists can keep their experiments organised.",
          additionalDescription:
            "With your support and fundraising, we fund long-term research. And when we fund long-term research, we make our biggest breakthroughs.",
        };

      case OTHER_MERCHANDISE:
        if (optInMerchandise === "yes") {
          return {
            header: "You've selected to receive a pack!",
            description:
              "We hope you're excited to receive your free merchandise and start your challenge for life-saving research! Kickstart your fundraising today, but please allow for 10 working days for your pack to arrive.",
            additionalDescription:
              "Sending out your pack comes at a cost to Cancer Research UK so please only order one pack if you intend to fundraise to ensure as much fundraising goes towards our life-saving research.",
          };
        }
        return {
          header: "You've opted out!",
          description:
            "By opting out to receiving merchandise, you're saving Cancer Research UK money, which we can now use to fund research. This saving could buy a test tube rack, which make sure our scientists can keep their experiments organised.",
          additionalDescription:
            "With your support and fundraising, we fund long-term research. And when we fund long-term research, we make our biggest breakthroughs.",
        };

      default:
        return { header: "", description: "" };
    }
  };

  // Add a default header if one hasn't been previously created
  const defaultMerchandiseHeader =
    merchandiseTemplateType === OTHER_MERCHANDISE
      ? "Would you like to receive a skipping rope?"
      : "Would you like to receive a t-shirt?";

  return (
    <>
      <LegendWrapper
        required
        aria-label="Select either Yes or No from the radio buttons to select opt in or out of receiving merchandise."
        errorMessage={errors?.optInMerchandise}
        legendText={merchandiseLabelHeader || defaultMerchandiseHeader}
      >
        <Radio
          aria-label="Select Yes Radio to opt in to receive merchandise."
          checked={optInMerchandise === "yes"}
          value="yes"
          onChange={handleChange}
          name="optInMerchandise"
          onClick={() => setOptInMerchandise(true)}
        >
          Yes
        </Radio>
        <Radio
          aria-label="Select No Radio to opt out of receiving merchandise."
          checked={optInMerchandise === "no"}
          value="no"
          onChange={handleChange}
          name="optInMerchandise"
          onClick={() => setOptInMerchandise(false)}
        >
          No
        </Radio>
      </LegendWrapper>

      <Box marginTop="s" marginBottom="m">
        {optInMerchandise !== "unknown" && (
          <InfoBox
            icon={
              <IconFa
                color="#008000"
                faIcon={{
                  icon: [
                    512,
                    512,
                    ["61533", "check-circle"],
                    "f058",
                    "M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM369 209L241 337c-9.4 9.4-24.6 9.4-33.9 0l-64-64c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0l47 47L335 175c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9z",
                  ],
                  iconName: "circle-check",
                  prefix: "fas",
                }}
                size="1.5em"
              />
            }
            titleText={infoBoxData().header}
            descriptionText={infoBoxData().description}
          >
            {infoBoxData()?.additionalDescription ? (
              <Text color="#000" marginTop="xs">
                {infoBoxData().additionalDescription}
                {infoBoxData()?.link}
              </Text>
            ) : null}
          </InfoBox>
        )}
      </Box>
    </>
  );
};

export default MerchandiseType;
