import { Box } from "@cruk/cruk-react-components";
import StyledTextAreaField from "../../components/StyledTextAreaField";
import { FormFieldProps } from "../../types";

const MoreInfo = ({
  field,
  isRequired,
  touched,
  errors,
  activity,
  getFieldProps,
}: FormFieldProps) => (
  <Box marginBottom="s" key={field}>
    <StyledTextAreaField
      label={activity.moreInfoHeader}
      required={isRequired("moreInfo")}
      {...getFieldProps("moreInfo")}
      hintText={activity.moreInfoHelpText}
      errorMessage={touched.moreInfo ? errors.moreInfo : undefined}
    />
  </Box>
);

export default MoreInfo;
