import { AnchorHTMLAttributes, FC } from "react";
import styled from "styled-components/macro";

const StyledSpaceAroundRows = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  border-left: none;
  border-right: none;
  width: 100%;
  height: auto;
`;

type SpaceAroundRowsProps = AnchorHTMLAttributes<{}>;

const SpaceAroundRows: FC<SpaceAroundRowsProps> = ({ children }) => (
  <StyledSpaceAroundRows>{children}</StyledSpaceAroundRows>
);

export default SpaceAroundRows;
