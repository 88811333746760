import { Box, Select } from "@cruk/cruk-react-components";
import { ChangeEvent } from "react";
import { RegistrationType } from "../../contexts/RegistrationContext";
import { FormFieldProps } from "../../types";
import dateOptions from "../../utils/dateOptions";

const ActivityMonth = ({
  values,
  field,
  formJourneyPage,
  handleChange,
  handleBlur,
  setValues,
}: FormFieldProps) => (
  <Box marginBottom="s" key={field}>
    <Select
      label={
        formJourneyPage.activityDateTimeLabel ||
        "When will you take on the challenge?"
      }
      name="activityDateTime"
      // always required by dropdown
      required
      value={values.activityDateTime as string}
      hintText={"Don't worry you can change this later"}
      onChange={(event: ChangeEvent<HTMLSelectElement>) => {
        setValues({ ...values } as RegistrationType);
        handleChange(event);
      }}
      onBlur={handleBlur}
    >
      {dateOptions.map(({ value, label, key }) => (
        <option value={value} key={key}>
          {label}
        </option>
      ))}
    </Select>
  </Box>
);

export default ActivityMonth;
