import {
  Box,
  Button,
  ErrorText,
  IconFa,
  InfoBox,
  Link,
  Select,
  Text,
  TextField,
} from "@cruk/cruk-react-components";
import { ChangeEvent, FC, useEffect, useRef, useState } from "react";
import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons";
import { Field, FieldProps, FormikErrors, FormikTouched } from "formik";
import countries from "../constants/countries.json";
import { RegistrationType } from "../contexts/RegistrationContext";
import { trackDataDogError } from "../utils/dataDog";
import isUkCountry from "../utils/isUkCountry";
import { JourneyHelper } from "../utils/journeyHelper";

type AddressLookupContainerProps = {
  values: RegistrationType;
  setValues: (values: RegistrationType) => void;
  handleBlur: (e: React.FocusEvent<unknown>) => void;
  handleChange: (e: ChangeEvent<unknown>) => void;
  touched: FormikTouched<RegistrationType>;
  errors: FormikErrors<RegistrationType>;
  setFieldValue: (
    field: string,
    value: unknown,
    shouldValidate?: boolean | undefined
  ) => void;
  journey: string;
  submitCount: number;
};

// used to disable autocomplete on address fields that are filled in by
// loqate search
const autoComplete = {
  autoComplete: `no-autofill-${Date.now()}`,
  // old edge: disable autocomplete by setting list attribute to a list that does not exist
  // https://stackoverflow.com/a/52608082
  list: "autocompleteOff",
};

interface AddressArrayType {
  value: string;
  data: string;
  label: string;
}

interface LoquateAddressItemType {
  Id: string;
  Type: string;
  Text: string;
  Highlight: string;
  Description: string;
  Error: string;
}

interface LoquateAddressRetriveItemType {
  Line1: string;
  Line2: string;
  Line3: string;
  Line4: string;
  Line5: string;
  Company: string;
  PostalCode: string;
  CountryName: string;
  City: string;
}

interface LoquateAddressResponse {
  Items: [LoquateAddressItemType];
}
interface LoquateRetriveAddressResponse {
  Items: [LoquateAddressRetriveItemType];
}

const AddressLookupContainer: FC<AddressLookupContainerProps> = ({
  values,
  setValues,
  handleBlur,
  handleChange,
  touched,
  errors,
  setFieldValue,
  journey,
  submitCount,
}) => {
  const apiKey = process.env.REACT_APP_PCA_KEY || "";
  const FIND_URL =
    "https://api.addressy.com/Capture/Interactive/Find/v1.1/json3.ws";
  const RETRIEVE_URL =
    "https://api.addressy.com/Capture/Interactive/Retrieve/v1/json3.ws";

  const [addressOptions, setAddressOptions] = useState<AddressArrayType[]>([]);
  const [addressActiveOption, setAddressActiveOption] = useState("");
  const [addressSearchStatus, setAddressSearchStatus] = useState(
    values.addressSearchStatus
  ); // "" | "manual" | "retrive" | "search"
  const [addressFieldsVisible, setAddressFieldsVisible] = useState(false);
  const [manualFieldsVisible, setManualFieldsVisible] = useState(false);
  // const [addressLookupErrorText, setAddressLookupErrorText] = useState("");

  const journeyHelper = new JourneyHelper();
  const buttonRef = useRef<HTMLButtonElement>(null);

  const inputs = [
    {
      value: "addressLine1",
      label: "Address line 1",
      required: true,
    },
    {
      value: "addressLine2",
      label: "Address line 2",
    },
    {
      value: "addressLine3",
      label: "Address line 3",
    },
    {
      value: "city",
      label: "City",
      required: true,
    },
  ];

  const countriesList = "GBR";

  const fieldSet = manualFieldsVisible
    ? [...inputs, { value: "postcode", label: "Postcode", required: true }]
    : inputs;

  const clearOptions = () => {
    setAddressActiveOption("");
    setAddressOptions([]);
  };

  // eslint-disable-next-line consistent-return
  const findAddress = (query: string, countryCode: string, id = "") => {
    if (query?.length === 0) return setAddressOptions([]);
    fetch(
      `${FIND_URL}?Key=${apiKey}&Text=${query}&Container=${id}&Countries=${countryCode}`
    )
      .then((res: Response) => {
        if (!res.ok) {
          // eslint-disable-next-line no-console
          console.error("Error", "Something went wrong please try again");
          trackDataDogError("Unable to find address!");
        }
        return res.json();
      })
      // eslint-disable-next-line consistent-return
      .then((data: LoquateAddressResponse) => {
        setAddressSearchStatus("search");
        if (data.Items[0] && data.Items[0].Error) {
          // eslint-disable-next-line no-console
          trackDataDogError(data.Items[0].Error);
          return console.error(data.Items[0]);
        }
        // eslint-disable-next-line promise/always-return
        if (data.Items[0].Type === "Postcode") {
          findAddress(values.postcode, countriesList, data.Items[0].Id);
        } else {
          const addressesArray = [];
          for (let i = 0; i < data.Items.length; i += 1) {
            const keyValueObject = {
              value: "",
              label: "",
              data: "",
            };
            keyValueObject.value = data.Items[i].Id;
            keyValueObject.data = data.Items[i].Type;
            const itemText = data.Items[i].Text;
            const itemDesc = data.Items[i].Description;
            if ((itemText + itemDesc).length < 90) {
              keyValueObject.label = `${data.Items[i].Text}, ${data.Items[i].Description}`;
            } else {
              keyValueObject.label = data.Items[i].Text;
            }
            addressesArray.push(keyValueObject);
          }

          setAddressOptions(addressesArray);
        }
      })
      // eslint-disable-next-line no-console
      .catch((err) => {
        trackDataDogError(err);
        console.error(err);
      });
  };

  const retrieve = (id: string) => {
    fetch(`${RETRIEVE_URL}?Key=${apiKey}&Id=${id}`)
      .then((res: Response) => {
        if (!res.ok) {
          // eslint-disable-next-line no-console
          console.error("Error", "Something went wrong please try again");
          trackDataDogError("Unable to retrieve address lookup!");
        }
        return res.json();
      })
      .then((data: LoquateRetriveAddressResponse) => {
        setAddressSearchStatus("retrieve");
        let field3 = data.Items[0].Line3;
        if (data.Items[0].Line4) field3 += `, ${data.Items[0].Line4}`;
        if (data.Items[0].Line5) field3 += `, ${data.Items[0].Line5}`;
        clearOptions();
        if (data.Items[0].Company) {
          setFieldValue("addressLine1", data.Items[0].Company);
          setFieldValue("addressLine2", data.Items[0].Line1);
          setFieldValue("addressLine3", data.Items[0].Line2);
        } else {
          setFieldValue("addressLine1", data.Items[0].Line1);
          setFieldValue("addressLine2", data.Items[0].Line2);
          setFieldValue("addressLine3", field3);
        }
        // only city field required to be sha-hashed
        setFieldValue("city", data.Items[0].City);
        setFieldValue("postcode", data.Items[0].PostalCode);
        const foundCountry = countries.find(
          // where c is country
          (c) => c.name === data.Items[0].CountryName
        );
        // eslint-disable-next-line promise/always-return
        if (foundCountry) {
          setFieldValue("country", foundCountry.code);
        }
      })
      // eslint-disable-next-line no-console
      .catch((err) => {
        trackDataDogError(err);
        console.error(err);
      });
  };

  useEffect(() => {
    if (!addressActiveOption) return;
    retrieve(addressActiveOption);
  }, [addressActiveOption]);

  useEffect(() => {
    setValues({ ...values, addressSearchStatus });
    addressSearchStatus === "retrieve"
      ? setAddressFieldsVisible(true)
      : setAddressFieldsVisible(false);

    if (addressSearchStatus === "manual") {
      setAddressFieldsVisible(true);
      setManualFieldsVisible(true);
    } else {
      setManualFieldsVisible(false);
    }
  }, [addressSearchStatus]);

  const checkFormAddressLookupError = () => {
    if (
      touched.postcode &&
      values.postcode &&
      !errors.postcode &&
      submitCount > 0 &&
      !addressSearchStatus
    ) {
      return true;
    }
    return false;
  };

  return (
    <>
      {!manualFieldsVisible && (
        <>
          <Box marginBottom="s">
            <TextField
              label="Postcode"
              type="text"
              name="postcode"
              id="postcode"
              hintText="Please enter a valid UK postcode to find address"
              required
              value={values.postcode}
              onKeyDown={(e: React.KeyboardEvent) => {
                if (e.code === "Enter") {
                  e.preventDefault();
                  buttonRef?.current?.click();
                }
                return true;
              }}
              onChange={(event: ChangeEvent<HTMLInputElement>) => {
                if (
                  addressSearchStatus === "retrieve" ||
                  addressSearchStatus === "search"
                ) {
                  setAddressSearchStatus("");
                  setAddressOptions([]);
                  fieldSet.forEach((fieldName) =>
                    setFieldValue(fieldName.value, "")
                  );
                }
                setValues({ ...values, validated: false });
                handleChange(event);
              }}
              onBlur={handleBlur}
              errorMessage={touched.postcode ? errors.postcode : undefined}
              {...autoComplete}
            />
          </Box>
          <Box>
            <Button
              appearance="secondary"
              type="button"
              ref={buttonRef}
              onClick={() => {
                if (!errors.postcode) {
                  findAddress(values.postcode, countriesList);
                }
              }}
            >
              Find address
            </Button>
          </Box>
          {checkFormAddressLookupError() && (
            <Box marginTop="none">
              <div role="alert" id="findAddress-error">
                <ErrorText>Please search for your address.</ErrorText>
              </div>
            </Box>
          )}
          {addressOptions.length > 0 && (
            <Box>
              <Select
                label="Select address"
                aria-label="Select address"
                onChange={(e): void => {
                  handleChange(e);
                  if (
                    addressOptions[
                      e.target.selectedIndex - 1
                    ].data.toLowerCase() !== "address"
                  ) {
                    findAddress(values.postcode, countriesList, e.target.value);
                  } else {
                    setAddressActiveOption(e.target.value);
                    setValues({ ...values, validated: true });
                  }
                }}
                onBlur={handleBlur}
                required
                name="addressActiveOption"
                errorMessage={
                  (touched.addressActiveOption || submitCount > 0) &&
                  !values.addressActiveOption
                    ? "Please select an address"
                    : undefined
                }
                value={addressActiveOption}
              >
                <option disabled value="" key="selectAddress">
                  Select Address
                </option>
                {addressOptions.map((address) => (
                  <option key={`select-${address.value}`} value={address.value}>
                    {address.label}
                  </option>
                ))}
              </Select>
            </Box>
          )}
        </>
      )}

      {addressSearchStatus === "manual" && (
        <Box marginBottom="s">
          <Text margin="none">
            To use the automated address finder, click on the link below
          </Text>
          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
          <Link
            type="button"
            href="#"
            aria-label="Search by postcode"
            onClick={() => {
              setAddressSearchStatus("search");
              setAddressFieldsVisible(true);
              clearOptions();
              fieldSet.forEach((fieldName) =>
                setFieldValue(fieldName.value, "")
              );
            }}
          >
            Search By Postcode
          </Link>{" "}
        </Box>
      )}

      {addressFieldsVisible && (
        <>
          {fieldSet.map((input, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <Field key={`${input.value}${index}`} name={input.value}>
              {({ field }: FieldProps): React.ReactElement => {
                const id = `${field.name}`;
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                const fieldTouched = touched[id];
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                const fieldError = errors[id] as string;
                return (
                  <Box marginBottom="s" key={field.name}>
                    <TextField
                      label={input.label}
                      type="text"
                      name={field.name}
                      required={input.required}
                      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                      // @ts-ignore
                      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                      value={values[id]}
                      onChange={(event: ChangeEvent<HTMLInputElement>) => {
                        setValues({ ...values, validated: false });
                        handleChange(event);
                      }}
                      // only city required to be sha-hashed
                      onBlur={handleBlur}
                      errorMessage={fieldTouched ? fieldError : undefined}
                      {...autoComplete}
                    />
                  </Box>
                );
              }}
            </Field>
          ))}
          <Box marginBottom="s">
            <Select
              label={
                journeyHelper.isSportsFormExternalJourney(journey)
                  ? "Where do you live?"
                  : "Country"
              }
              name="country"
              required
              value={values.country}
              onChange={(event: ChangeEvent<HTMLSelectElement>) => {
                setValues({ ...values, validated: false });
                handleChange(event);
              }}
              onBlur={handleBlur}
            >
              {countries.map(({ code, name }) => (
                <option value={code} key={code}>
                  {name}
                </option>
              ))}
            </Select>
          </Box>
          {(journeyHelper.isSportsFormJourney(journey) ||
            journeyHelper.isInternationalAddressReminder(journey)) &&
            !isUkCountry(values.country) && (
              <InfoBox
                marginBottom="s"
                titleText="Outside the UK?"
                className="infoBoxHeading"
                descriptionText=" Sorry, we only post fundraising packs within
                            the United Kingdom."
                icon={
                  <IconFa
                    faIcon={faExclamationCircle}
                    size="1.5rem"
                    color="#fdc02e"
                  />
                }
              >
                <Text>
                  We can still offer you other fundraising support and a Cancer
                  Research UK Giving page
                </Text>
              </InfoBox>
            )}
        </>
      )}
      {addressSearchStatus !== "manual" && (
        <Box>
          <Text margin="none">
            To enter address manually or to enter an address outside the UK, use
            the link below
          </Text>
          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
          <Link
            type="button"
            href="#"
            aria-label="Enter address manually"
            style={{ textDecoration: "underline" }}
            onClick={() => {
              setAddressSearchStatus("manual");
              clearOptions();
              setAddressFieldsVisible(true);
              fieldSet.forEach((fieldName) =>
                setFieldValue(fieldName.value, "")
              );
            }}
          >
            Enter address manually
          </Link>
        </Box>
      )}
    </>
  );
};
export default AddressLookupContainer;
